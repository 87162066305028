import * as React from "react";

function CalendarMonthNov24(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.49023 2.55469V4.91602M17.5098 2.55469V4.91602M2.55469 19.084V7.27734C2.55469 5.97322 3.61189 4.91602 4.91602 4.91602H19.084C20.3881 4.91602 21.4453 5.97322 21.4453 7.27734V19.084M2.55469 19.084C2.55469 20.3881 3.61189 21.4453 4.91602 21.4453H19.084C20.3881 21.4453 21.4453 20.3881 21.4453 19.084M2.55469 19.084V11.2129M21.4453 19.084V11.2129"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1049 17.3545C10.1049 17.5322 10.0441 17.6866 9.92248 17.8176C9.81021 17.9392 9.66521 18 9.48746 18H9.09455C8.9168 18 8.76244 17.9392 8.63147 17.8176C8.50985 17.6866 8.44904 17.5322 8.44904 17.3545V10.9696C8.44904 10.9322 8.43033 10.9088 8.39291 10.8994C8.36484 10.8807 8.34146 10.8807 8.32275 10.8994L8.11225 11.1099C7.99999 11.2409 7.85031 11.3064 7.66321 11.3064C7.56966 11.3064 7.48546 11.2877 7.41062 11.2503C7.289 11.2035 7.19545 11.124 7.12996 11.0117C7.06448 10.8994 7.03174 10.7825 7.03174 10.6609V10.1136C7.03174 9.93586 7.09254 9.7815 7.21416 9.65053L8.16839 8.71034C8.23387 8.65421 8.30404 8.60743 8.37888 8.57001C8.45372 8.53259 8.53791 8.51388 8.63147 8.51388H9.48746C9.66521 8.51388 9.81021 8.57469 9.92248 8.6963C10.0441 8.81792 10.1049 8.97228 10.1049 9.15938V17.3545ZM16.9678 17.3545C16.9678 17.5322 16.907 17.6866 16.7854 17.8176C16.6731 17.9392 16.5281 18 16.3503 18H15.9574C15.7797 18 15.6253 17.9392 15.4943 17.8176C15.3727 17.6866 15.3119 17.5322 15.3119 17.3545V10.9696C15.3119 10.9322 15.2932 10.9088 15.2558 10.8994C15.2277 10.8807 15.2043 10.8807 15.1856 10.8994L14.9751 11.1099C14.8629 11.2409 14.7132 11.3064 14.5261 11.3064C14.4325 11.3064 14.3483 11.2877 14.2735 11.2503C14.1519 11.2035 14.0583 11.124 13.9928 11.0117C13.9274 10.8994 13.8946 10.7825 13.8946 10.6609V10.1136C13.8946 9.93586 13.9554 9.7815 14.077 9.65053L15.0313 8.71034C15.0967 8.65421 15.1669 8.60743 15.2418 8.57001C15.3166 8.53259 15.4008 8.51388 15.4943 8.51388H16.3503C16.5281 8.51388 16.6731 8.57469 16.7854 8.6963C16.907 8.81792 16.9678 8.97228 16.9678 9.15938V17.3545Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CalendarMonthNov24;
