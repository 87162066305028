import { useCallback, useMemo, useState } from "react";
import {
  classNames,
  getPeriodName,
  isNotNullAndNotUndefined,
} from "../../../src/formatting";
import {
  PeriodStatus,
  useLockPeriodAndCreateNextMutation,
  useLockPeriodMutation,
  useOpenNextPeriodMutation,
  usePeriodsQuery,
  useUnlockPeriodMutation,
  useUpdateUserSelectedPeriodMutation,
} from "../../../src/generated/graphql";
import Select, { Option } from "../../../components/Select";
import { useLoggedInUser } from "../../../components/LoggedInUserProvider";
import { assertDefined, findOrFail } from "../../../src/utils";
import useImportPeriod from "../../../hooks/useImportPeriod";
import { PlusIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { Button } from "../../Button";
import LockPeriodModal from "./LockPeriodModal";
import OpenNextPeriodModal from "./OpenNextPeriodModal";
import { ListboxButton } from "@headlessui/react";
import { NavbarItem } from "../../../core/Navbar";
import UnlockIcon16 from "../../icons/UnlockIcon16";
import LockIcon16 from "../../icons/LockIcon16";
import UnlockPeriodModal from "./UnlockPeriodModal";
import { CheckIcon } from "@heroicons/react/20/solid";
import CalendarMonth24 from "../../icons/calendar/CalendarMonth24";

export const PeriodSelector = () => {
  const { user } = useLoggedInUser();
  const { data: periodsData, refetch: refetchPeriods } = usePeriodsQuery();
  const { importPeriod, refetch: refetchImportPeriod } = useImportPeriod();

  const defaultOpenPeriod = periodsData?.openPeriod;
  const periods = useMemo(
    () => periodsData?.periods || [],
    [periodsData?.periods]
  );

  const [unlockPeriod] = useUnlockPeriodMutation();
  const [lockPeriod] = useLockPeriodMutation();
  const [lockPeriodAndCreateNext] = useLockPeriodAndCreateNextMutation();
  const [openNextPeriod] = useOpenNextPeriodMutation();
  const [updateUserSelectedPeriod] = useUpdateUserSelectedPeriodMutation();

  const [showLockPeriodModal, setShowLockPeriodModal] = useState(false);
  const [showOpenNextPeriodModal, setShowOpenNextPeriodModal] = useState(false);
  const [selectedPeriodToUnlock, setSelectedPeriodToUnlock] = useState<
    string | null
  >(null);

  const periodOptions: Option[] = useMemo(
    () =>
      periods.map((period) => {
        const isPeriodClosed = period.status === PeriodStatus.CLOSED;

        return {
          id: period.id,
          value: getPeriodName(period),
          optionClasses: classNames(
            isPeriodClosed && "cursor-not-allowed bg-unset"
          ),
          optionElement: (selected: boolean, active: boolean) => (
            <div className="flex justify-between items-center">
              <span
                className={classNames(
                  isPeriodClosed && "opacity-50",
                  selected && "text-emerald-700"
                )}
              >
                {getPeriodName(period)}
              </span>
              {isPeriodClosed &&
                (active || selectedPeriodToUnlock === period.id ? (
                  <Button
                    className="!p-1 -my-1 -mx-1.5"
                    colorScheme="white"
                    onClick={(event) => {
                      setSelectedPeriodToUnlock(period.id);
                      event.stopPropagation();
                    }}
                  >
                    <UnlockIcon16 />
                  </Button>
                ) : (
                  <LockIcon16 className="opacity-50" />
                ))}
              {selected ? (
                <span className="text-emerald-700 absolute inset-y-0 left-0 flex items-center pl-2">
                  <div className="w-5 h-5 flex items-center justify-center">
                    <CheckIcon className="w-4 h-4" />
                  </div>
                </span>
              ) : null}
            </div>
          ),
        };
      }),
    [periods, selectedPeriodToUnlock]
  );

  const selectedPeriodOption =
    importPeriod && periodOptions.length > 0
      ? periodOptions.find(({ id }) => id === importPeriod.id)
      : null;

  const nextPeriodDate = useMemo(() => {
    // Get the latest open period to determine the next period's start date
    const latestOpenPeriod = periods?.find(
      (p) => p.status === PeriodStatus.OPEN
    );

    // Add a month on the latest open period's start date
    if (latestOpenPeriod) {
      return DateTime.fromISO(latestOpenPeriod.rangeStart, {
        zone: "utc",
      })
        .plus({ months: 1 })
        .toFormat("MMMM yyyy");
    }

    return null;
  }, [periods]);

  const handleLockPeriod = useCallback(async () => {
    if (!defaultOpenPeriod || !importPeriod) return;

    if (defaultOpenPeriod.id === importPeriod.id) {
      await lockPeriodAndCreateNext({
        variables: { id: defaultOpenPeriod.id },
      });
    } else {
      await lockPeriod({
        variables: { id: importPeriod.id },
      });
    }

    await Promise.all([await refetchPeriods(), await refetchImportPeriod()]);

    setShowLockPeriodModal(false);
  }, [
    defaultOpenPeriod,
    importPeriod,
    refetchPeriods,
    refetchImportPeriod,
    lockPeriodAndCreateNext,
    lockPeriod,
  ]);

  const handleUnlockPeriod = useCallback(async () => {
    if (!selectedPeriodToUnlock) return;

    await unlockPeriod({
      variables: { id: selectedPeriodToUnlock },
    });

    setSelectedPeriodToUnlock(null);
  }, [selectedPeriodToUnlock, unlockPeriod]);

  const handleOpenNextPeriod = useCallback(async () => {
    const openPeriod = periods?.find((p) => p.status === PeriodStatus.OPEN);

    await openNextPeriod({
      variables: {
        currentDefaultOpenPeriodId: openPeriod?.id,
      },
    });

    await Promise.all([await refetchPeriods(), await refetchImportPeriod()]);

    setShowOpenNextPeriodModal(false);
  }, [periods, openNextPeriod, refetchPeriods, refetchImportPeriod]);

  const handleImportPeriodChange = useCallback(
    async ({ id: periodId }: Option) => {
      assertDefined(user);

      const period = findOrFail(periods, ({ id }) => id === periodId);

      if (period.status === PeriodStatus.OPEN) {
        await updateUserSelectedPeriod({
          variables: {
            periodId,
          },
          optimisticResponse: {
            updateUserSelectedPeriod: {
              __typename: "User",
              id: user?.id,
              selectedPeriod: {
                __typename: "Period",
                id: periodId,
              },
            },
          },
        });
      }
    },
    [periods, updateUserSelectedPeriod, user]
  );

  return (
    <>
      {defaultOpenPeriod && importPeriod && (
        <LockPeriodModal
          defaultOpenPeriod={defaultOpenPeriod}
          selectedPeriod={importPeriod}
          open={showLockPeriodModal}
          setOpen={setShowLockPeriodModal}
          onConfirm={handleLockPeriod}
          nextPeriodDate={nextPeriodDate ?? ""}
        />
      )}
      <UnlockPeriodModal
        open={isNotNullAndNotUndefined(selectedPeriodToUnlock)}
        setOpen={() => setSelectedPeriodToUnlock(null)}
        onConfirm={handleUnlockPeriod}
      />
      <OpenNextPeriodModal
        open={showOpenNextPeriodModal}
        setOpen={setShowOpenNextPeriodModal}
        onConfirm={handleOpenNextPeriod}
        periodToOpen={nextPeriodDate ?? "next"}
      />
      <div className="block">
        <Select
          menuClasses="w-[220px] divide-y"
          anchor="bottom end"
          overrideButtonEl={
            <ListboxButton as={NavbarItem} onClick={() => refetchPeriods()}>
              {importPeriod && (
                <CalendarMonth24
                  month={
                    DateTime.fromISO(importPeriod.rangeStart).toUTC().month
                  }
                  className="h-6 w-6 text-zinc-500 fade-in-out"
                />
              )}
            </ListboxButton>
          }
          borderless={true}
          options={periodOptions}
          selectedOption={selectedPeriodOption}
          onChange={handleImportPeriodChange}
          fixedEmbed={
            <>
              <div
                onClick={() => setShowLockPeriodModal(true)}
                className="flex items-center py-2 px-3 space-x-1 cursor-pointer hover:bg-zinc-100"
              >
                <LockIcon16 className="w-5 h-5 text-zinc-400" />
                <div className="text-sm leading-none font-normal text-zinc-800">
                  Lock period
                </div>
              </div>
              <div
                onClick={() => setShowOpenNextPeriodModal(true)}
                className="flex items-center py-2 px-3 space-x-1 cursor-pointer hover:bg-zinc-100"
              >
                <PlusIcon className="w-5 h-5 text-zinc-400" />
                <div className="text-sm leading-none font-normal text-zinc-800">
                  Unlock next period
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};
