import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

type AnimatedChevronProps = {
  open: boolean;
};

export default function AnimatedChevron({ open }: AnimatedChevronProps) {
  return (
    <ChevronDownIcon
      className={clsx(
        "text-zinc-400 transition-transform group-hover:text-zinc-600 size-4",
        open && "rotate-180"
      )}
    />
  );
}
