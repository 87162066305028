"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyMetaFieldMatchConfigs = void 0;
const graphql_1 = require("../../generated/graphql");
const _ = __importStar(require("lodash"));
const utils_1 = require("./utils");
const utils_2 = require("../../common/utils");
/**
 * Generates new fields from references to labeled fields to handle custom config
 * overrides, duplicate fields, etc.
 *
 * @param matchConfigs
 * @param overrideCustomFields
 * @returns
 */
const applyMetaFieldMatchConfigs = (matchConfigs, overrideCustomFields = [], overrideRowMatchFilters = [], overrideRowSignatureFlags = [], flags = {}) => {
    const { mergeFields, policyCharPatternMatchThreshold, dateCharPatternMatchThreshold, ignoreFillerBlocks, disableMergedFields, } = flags;
    // Combine relevant fields + specify child fields
    let extendedFieldMatchConfigs = generateBaseExtendedFieldMatchConfigs(matchConfigs, overrideCustomFields, overrideRowMatchFilters);
    // Apply row-signature level flags (note distinct from row-signature *group* level flags)
    extendedFieldMatchConfigs = applyRowSignatureFlags(extendedFieldMatchConfigs, overrideRowSignatureFlags);
    // Apply filter functions for filtering row matches via their text
    extendedFieldMatchConfigs = applyRowMatchFilters(extendedFieldMatchConfigs, overrideRowMatchFilters);
    // Apply filter + extractor functions based off block match policies
    extendedFieldMatchConfigs = applyBlockMatchPolicies(extendedFieldMatchConfigs, !!ignoreFillerBlocks);
    // Apply filter functions for filtering field matches via their character content
    extendedFieldMatchConfigs = applyCharacterPatternFilters(extendedFieldMatchConfigs, policyCharPatternMatchThreshold, dateCharPatternMatchThreshold);
    if (!disableMergedFields) {
        // Automatically merge specified fields
        extendedFieldMatchConfigs = getMergedMetaFields(extendedFieldMatchConfigs, mergeFields || [], !!ignoreFillerBlocks);
    }
    return extendedFieldMatchConfigs;
};
exports.applyMetaFieldMatchConfigs = applyMetaFieldMatchConfigs;
/**
 * Converts AdemFieldMatchConfig configs to ExtendedAdemFieldMatchConfig configs.
 * This handles transforming/combining fields into a single field when applicable
 * and specifying child fields.
 */
const generateBaseExtendedFieldMatchConfigs = (matchConfigs, overrideCustomFields = [], overrideRowMatchFilters = []) => {
    const customFields = [
        ...overrideCustomFields,
        ...getDuplicateMetaFields(matchConfigs, overrideCustomFields),
    ];
    if (!customFields.length && !overrideRowMatchFilters.length) {
        return matchConfigs.map(({ field, fieldName, fieldMatchConfig }) => [
            fieldName,
            Object.assign(Object.assign({}, fieldMatchConfig), { childFields: [fieldName], type: (0, utils_1.getFieldType)(field) }),
        ]);
    }
    const boundaryConfigHeaders = matchConfigs.map(({ fieldName }) => fieldName);
    const metaFieldMatchConfigs = customFields
        .map((override) => {
        let startBound = override.matchConfig.startBound;
        let leftEndBound = override.matchConfig.endBound.left;
        let rightEndBound = override.matchConfig.endBound.right;
        if ((0, utils_1.isRelativeFieldBoundaryConfig)(startBound) &&
            !boundaryConfigHeaders.includes(startBound.field)) {
            return null;
        }
        const boundaryConfigObj = _.keyBy(matchConfigs, "fieldName");
        if ((0, utils_1.isRelativeFieldBoundaryConfig)(startBound)) {
            startBound = (boundaryConfigObj[startBound.field] ||
                boundaryConfigObj[override.name]).fieldMatchConfig.startBound;
        }
        if (leftEndBound && (0, utils_1.isRelativeFieldBoundaryConfig)(leftEndBound)) {
            leftEndBound = (boundaryConfigObj[leftEndBound.field] ||
                boundaryConfigObj[override.name]).fieldMatchConfig.endBound.left;
        }
        if (rightEndBound && (0, utils_1.isRelativeFieldBoundaryConfig)(rightEndBound)) {
            rightEndBound = (boundaryConfigObj[rightEndBound.field] ||
                boundaryConfigObj[override.name]).fieldMatchConfig.endBound.right;
        }
        const result = [
            override.name,
            Object.assign(Object.assign({}, override.matchConfig), { type: graphql_1.AdemFieldType.STRING, extractor: override.extractor, childFields: override.childFields, requireUniqueRowMatches: override.requireUniqueRowMatches, requireStrictlyUniqueRowMatches: override.requireStrictlyUniqueRowMatches, meanBlockGap: override.meanBlockGap, startBound, endBound: {
                    left: leftEndBound,
                    right: rightEndBound,
                } }),
        ];
        return result;
    })
        .filter(utils_2.isNotNullAndNotUndefined);
    // Filter out child fields from original match configs to prevent duplicate matches
    // and remove shadow fields if there is corresponding row match filter
    const customFieldChildFields = customFields.flatMap((override) => [
        ...override.childFields,
        ...((0, utils_1.isRelativeFieldBoundaryConfig)(override.matchConfig.startBound)
            ? [override.matchConfig.startBound.field]
            : []),
    ]);
    const rowMatchChildFields = overrideRowMatchFilters.flatMap((override) => override.childFields);
    const filteredFields = matchConfigs
        .filter(({ fieldName }) => !customFieldChildFields.includes((0, utils_1.getBaseField)(fieldName)) &&
        (!rowMatchChildFields.includes((0, utils_1.getBaseField)(fieldName)) ||
            !(0, utils_1.isShadowField)(fieldName)))
        .map(({ field, fieldName, fieldMatchConfig }) => [
        fieldName,
        Object.assign(Object.assign({}, fieldMatchConfig), { childFields: [fieldName], type: (0, utils_1.getFieldType)(field) }),
    ]);
    return [...filteredFields, ...metaFieldMatchConfigs];
};
/**
 * Detects when multiple fields share the same column, as determined by different field
 * configs having the same start bound. If this occurs, creates a single new "meta field"
 * to replace the original fields.
 *
 * @param matchConfigs
 * @returns
 */
const getDuplicateMetaFields = (matchConfigs, overrideCustomFields) => Object.entries(_.groupBy(matchConfigs, ({ fieldMatchConfig }) => JSON.stringify(fieldMatchConfig.startBound)))
    .map(([, configs]) => {
    if (configs.length > 1) {
        const childFields = configs.map(({ fieldName }) => fieldName);
        if (overrideCustomFields.every(({ childFields }) => childFields.every((overrideField) => !childFields.includes(overrideField)))) {
            return {
                name: childFields.join("-"),
                childFields,
                matchConfig: configs[0].fieldMatchConfig,
                extractor: (blocks) => Object.fromEntries(childFields.map((field) => [field, blocks])),
            };
        }
    }
    return null;
})
    .filter(utils_2.isNotNullAndNotUndefined);
const applyRowMatchFilters = (matchConfigs, overrideRowMatchFilters) => matchConfigs
    .map(([field, config]) => {
    const rowMatchFilter = overrideRowMatchFilters.find((rowMatchFilter) => rowMatchFilter.childFields.includes(field));
    if (rowMatchFilter) {
        return [
            field,
            Object.assign(Object.assign({}, config), { filter: (rowBlocks, matchedBlocks) => {
                    var _a;
                    return (!config.filter || ((_a = config.filter) === null || _a === void 0 ? void 0 : _a.call(config, rowBlocks, matchedBlocks))) &&
                        rowMatchFilter.filter(rowBlocks.map((block) => block.text).join(" "), matchedBlocks.map((block) => block.text).join(" "));
                }, skipSparseFieldValidation: true }),
        ];
    }
    else {
        return [field, config];
    }
})
    .sort(([, configA], [, configB]) => (0, utils_1.sortByPosition)(configA, configB));
const applyRowSignatureFlags = (matchConfigs, overrideRowSignatureFlags) => matchConfigs.map(([field, config]) => {
    const rowSignatureFlags = overrideRowSignatureFlags.find((rowSignatureFlags) => rowSignatureFlags.childFields.includes(field));
    if (rowSignatureFlags) {
        return [
            field,
            Object.assign(Object.assign({}, config), _.omit(rowSignatureFlags, "childFields")),
        ];
    }
    else {
        return [field, config];
    }
});
/**
 * Generates filter + extractor functions from the provided block match
 * policy if present. Currently the only supported policy is the FIXED_ELIMINATION
 * policy, which eliminates a fixed number of blocks from the output.
 *
 * @param matchConfigs
 * @returns
 */
const applyBlockMatchPolicies = (matchConfigs, ignoreFillerBlocks) => matchConfigs.map(([header, config]) => {
    const policy = config.blockMatchPolicy;
    return [
        header,
        Object.assign(Object.assign(Object.assign({}, config), ((0, utils_2.isNotNullAndNotUndefined)(policy) &&
            (0, utils_1.isFixedEliminationBlockMatchPolicy)(policy) && {
            extractor: (blocks) => ({
                [header]: partitionMatchedBlocksFromEnd(blocks, policy.numEndEliminationBlocks, ignoreFillerBlocks)[0],
            }),
        })), ((0, utils_2.isNotNullAndNotUndefined)(policy) &&
            (0, utils_1.isFixedEliminationBlockMatchPolicy)(policy) && {
            filter: (rowBlocks, blocks) => {
                var _a;
                return (!config.filter || ((_a = config.filter) === null || _a === void 0 ? void 0 : _a.call(config, rowBlocks, blocks))) &&
                    (0, utils_1.getValidBlockTypes)(partitionMatchedBlocksFromEnd(blocks, policy.numEndEliminationBlocks, ignoreFillerBlocks)[1]).includes(policy.eliminationMatchType);
            },
        })),
    ];
});
const partitionMatchedBlocksFromStart = (matchedBlocks, numStartBlocks, ignoreFillerBlocks) => {
    let blockInd = 0;
    let numMatchedBlocks = 0;
    while (blockInd < matchedBlocks.length && numMatchedBlocks < numStartBlocks) {
        if ((0, utils_1.blockContainsValidFixedContent)(matchedBlocks[blockInd], ignoreFillerBlocks)) {
            numMatchedBlocks += 1;
        }
        blockInd += 1;
    }
    let nextBlockInd = blockInd;
    while (nextBlockInd < matchedBlocks.length &&
        !(0, utils_1.blockContainsValidFixedContent)(matchedBlocks[nextBlockInd], ignoreFillerBlocks)) {
        nextBlockInd += 1;
    }
    return [
        matchedBlocks.slice(0, blockInd),
        matchedBlocks.slice(nextBlockInd, matchedBlocks.length),
    ];
};
/**
 * Splits blocks in two groups, with second group containing the last
 * numEndBlocks valid matched blocks (according to whether filler blocks
 * are ignored).
 *
 * @param matchedBlocks
 * @param numEndBlocks
 * @param ignoreFillerBlocks
 * @returns
 */
const partitionMatchedBlocksFromEnd = (matchedBlocks, numEndBlocks, ignoreFillerBlocks) => {
    let blockInd = matchedBlocks.length - 1;
    let numMatchedBlocks = 0;
    while (blockInd >= 0 && numMatchedBlocks < numEndBlocks) {
        if ((0, utils_1.blockContainsValidFixedContent)(matchedBlocks[blockInd], ignoreFillerBlocks)) {
            numMatchedBlocks += 1;
        }
        blockInd -= 1;
    }
    while (blockInd >= 0 &&
        !(0, utils_1.blockContainsValidFixedContent)(matchedBlocks[blockInd], ignoreFillerBlocks)) {
        blockInd -= 1;
    }
    return [
        matchedBlocks.slice(0, blockInd + 1),
        matchedBlocks.slice(blockInd + 1, matchedBlocks.length),
    ];
};
const applyCharacterPatternFilters = (matchConfigs, policyCharPatternMatchThreshold, dateCharPatternMatchThreshold) => matchConfigs.map(([header, config]) => {
    const fieldCharacterPattern = config.characterPattern;
    if ((0, utils_2.isNotNullAndNotUndefined)(fieldCharacterPattern)) {
        if (header === "Policy #" &&
            (0, utils_2.isNotNullAndNotUndefined)(policyCharPatternMatchThreshold)) {
            return addCharacterPatternFilterToConfig([header, config], policyCharPatternMatchThreshold);
        }
        if (header === "Date" &&
            (0, utils_2.isNotNullAndNotUndefined)(dateCharPatternMatchThreshold)) {
            return addCharacterPatternFilterToConfig([header, config], dateCharPatternMatchThreshold);
        }
    }
    return [header, config];
});
const addCharacterPatternFilterToConfig = ([header, config], charPatternMatchThreshold) => [
    header,
    Object.assign(Object.assign({}, config), { filter: (rowBlocks, matchedBlocks) => {
            var _a;
            return (!config.filter || ((_a = config.filter) === null || _a === void 0 ? void 0 : _a.call(config, rowBlocks, matchedBlocks))) &&
                (0, utils_2.levenshteinDistance)(config.characterPattern, (0, utils_1.getCharacterPattern)(matchedBlocks)) <= charPatternMatchThreshold;
        } }),
];
/**
 * Merges specified fields into a singular meta field with a config combined
 * from each of its child configs. For now, this expects there to be exactly two
 * match fields and for the second match field to have a fixed block match policy.
 *
 * @param matchConfigs
 * @param mergeFields
 * @returns
 */
const getMergedMetaFields = (matchConfigs, mergeFields, ignoreFillerBlocks) => {
    const matchConfigFields = matchConfigs.map(([field]) => field);
    if (!mergeFields.length ||
        !mergeFields.every((field) => matchConfigFields.includes(field))) {
        return matchConfigs;
    }
    const [fieldA, configA] = (0, utils_2.findOrFail)(matchConfigs, ([field]) => field === mergeFields[0]);
    const [fieldB, configB] = (0, utils_2.findOrFail)(matchConfigs, ([field]) => field === mergeFields[1]);
    if (configA.alignment === graphql_1.AdemFieldAlignment.LEFT &&
        configB.endBound.right &&
        (0, utils_1.isFixedBlocksBoundaryConfig)(configB.endBound.right)) {
        const numBlocksB = configB.endBound.right.numBlocks;
        const expectNumericMatchB = configA.endBound.right &&
            (0, utils_1.isCellMatchBoundaryConfig)(configA.endBound.right) &&
            configA.endBound.right.cellMatchType === graphql_1.AdemFieldType.NUMBER;
        const mergedConfig = {
            type: graphql_1.AdemFieldType.STRING,
            childFields: [...configA.childFields, ...configB.childFields],
            startBound: configA.startBound,
            endBound: {
                left: null,
                right: {
                    type: graphql_1.AdemBoundaryConfigType.POSITION,
                    positionX: 1.5,
                },
            },
            alignment: configA.alignment,
            isStartField: configA.isStartField,
            isEndField: configB.isEndField,
            extractor: (matchedBlocks) => {
                const [matchedBlocksA, matchedBlocksB] = partitionMatchedBlocksFromEnd(matchedBlocks, numBlocksB, ignoreFillerBlocks);
                return {
                    [fieldA]: configA.extractor
                        ? configA.extractor(matchedBlocksA)[fieldA]
                        : matchedBlocksA,
                    [fieldB]: configB.extractor
                        ? configB.extractor(matchedBlocksB)[fieldB]
                        : matchedBlocksB,
                };
            },
            filter: (rowBlocks, matchedBlocks) => {
                const [matchedBlocksA, matchedBlocksB] = partitionMatchedBlocksFromEnd(matchedBlocks, numBlocksB, ignoreFillerBlocks);
                return ((!configA.filter || configA.filter(rowBlocks, matchedBlocksA)) &&
                    (!configB.filter || configB.filter(rowBlocks, matchedBlocksB)) &&
                    matchedBlocksA.length > 0 &&
                    matchedBlocksB.length > 0 &&
                    (!expectNumericMatchB ||
                        /\d+/.test(matchedBlocksB.map((b) => b.text).join(""))));
            },
        };
        return [[`Merged-${fieldA}-${fieldB}`, mergedConfig]];
    }
    if (configA.alignment === graphql_1.AdemFieldAlignment.LEFT &&
        configA.endBound.right &&
        configB.endBound.right &&
        (0, utils_1.isFixedBlocksBoundaryConfig)(configA.endBound.right) &&
        !(0, utils_1.isFixedBlocksBoundaryConfig)(configB.endBound.right)) {
        const numBlocksA = configA.endBound.right.numBlocks;
        const mergedConfig = {
            type: graphql_1.AdemFieldType.STRING,
            childFields: [...configA.childFields, ...configB.childFields],
            startBound: configA.startBound,
            endBound: configB.endBound,
            alignment: configA.alignment,
            isStartField: configA.isStartField,
            isEndField: configB.isEndField,
            meanBlockGap: 0.1,
            extractor: (matchedBlocks) => {
                const [matchedBlocksA, matchedBlocksB] = partitionMatchedBlocksFromStart(matchedBlocks, numBlocksA, ignoreFillerBlocks);
                return {
                    [fieldA]: configA.extractor
                        ? configA.extractor(matchedBlocksA)[fieldA]
                        : matchedBlocksA,
                    [fieldB]: configB.extractor
                        ? configB.extractor(matchedBlocksB)[fieldB]
                        : matchedBlocksB,
                };
            },
            filter: (rowBlocks, matchedBlocks) => {
                const [matchedBlocksA, matchedBlocksB] = partitionMatchedBlocksFromStart(matchedBlocks, numBlocksA, ignoreFillerBlocks);
                return ((!configA.filter || configA.filter(rowBlocks, matchedBlocksA)) &&
                    (!configB.filter || configB.filter(rowBlocks, matchedBlocksB)) &&
                    matchedBlocksA.length > 0 &&
                    matchedBlocksB.length > 0);
            },
        };
        const configAIndex = matchConfigs.findIndex(([field]) => field === mergeFields[0]);
        matchConfigs.splice(configAIndex, 2, [
            `Merged-${fieldA}-${fieldB}`,
            mergedConfig,
        ]);
    }
    return matchConfigs;
};
