import { useLDClient } from "launchdarkly-react-client-sdk";

// Returns a callback that can be used to add a user to the LaunchDarkly
// context. This is useful for adding user attributes to the LaunchDarkly
// context, such as the user's email address or ID.
export default function useAddFeatureFlagContext() {
  const ldClient = useLDClient();

  return ldClient?.identify;
}
