"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isStringImportFieldConfig = exports.isNumericImportFieldConfig = exports.isDateImportFieldConfig = exports.isAdvancedFormulaAccessor = exports.isRelativeWordRangeAccessor = exports.isManualDateSetAccessor = exports.isStaticDateColumnOperationAccessor = exports.isStaticColumnOperationAccessor = exports.isBinaryColumnOperationAccessor = exports.isColumnConcatenateAccessor = exports.isColumnCoalesceAccessor = exports.isColumnKeyAccessor = void 0;
const graphql_1 = require("../generated/graphql");
const isColumnKeyAccessor = (accessor) => accessor.type === graphql_1.ImportFieldAccessorType.COLUMN_KEY;
exports.isColumnKeyAccessor = isColumnKeyAccessor;
const isColumnCoalesceAccessor = (accessor) => accessor.type === graphql_1.ImportFieldAccessorType.COLUMN_COALESCE;
exports.isColumnCoalesceAccessor = isColumnCoalesceAccessor;
const isColumnConcatenateAccessor = (accessor) => accessor.type === graphql_1.ImportFieldAccessorType.COLUMN_CONCATENATE;
exports.isColumnConcatenateAccessor = isColumnConcatenateAccessor;
const isBinaryColumnOperationAccessor = (accessor) => accessor.type === graphql_1.ImportFieldAccessorType.BINARY_COLUMN_OPERATION;
exports.isBinaryColumnOperationAccessor = isBinaryColumnOperationAccessor;
const isStaticColumnOperationAccessor = (accessor) => accessor.type === graphql_1.ImportFieldAccessorType.STATIC_COLUMN_OPERATION;
exports.isStaticColumnOperationAccessor = isStaticColumnOperationAccessor;
const isStaticDateColumnOperationAccessor = (accessor) => accessor.type === graphql_1.ImportFieldAccessorType.STATIC_DATE_COLUMN_OPERATION;
exports.isStaticDateColumnOperationAccessor = isStaticDateColumnOperationAccessor;
const isManualDateSetAccessor = (accessor) => accessor.type === graphql_1.ImportFieldAccessorType.MANUAL_DATE_SET;
exports.isManualDateSetAccessor = isManualDateSetAccessor;
const isRelativeWordRangeAccessor = (accessor) => accessor.type === graphql_1.ImportFieldAccessorType.RELATIVE_WORD_RANGE;
exports.isRelativeWordRangeAccessor = isRelativeWordRangeAccessor;
const isAdvancedFormulaAccessor = (accessor) => accessor.type === graphql_1.ImportFieldAccessorType.ADVANCED_FORMULA;
exports.isAdvancedFormulaAccessor = isAdvancedFormulaAccessor;
const isDateImportFieldConfig = (config) => config.fieldType === graphql_1.ImportFieldType.DATE;
exports.isDateImportFieldConfig = isDateImportFieldConfig;
const isNumericImportFieldConfig = (config) => config.fieldType === graphql_1.ImportFieldType.NUMBER;
exports.isNumericImportFieldConfig = isNumericImportFieldConfig;
const isStringImportFieldConfig = (config) => config.fieldType === graphql_1.ImportFieldType.STRING;
exports.isStringImportFieldConfig = isStringImportFieldConfig;
