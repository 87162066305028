// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
/* eslint-disable no-undef */

import * as Sentry from "@sentry/nextjs";
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_DISABLED = process.env.NEXT_PUBLIC_SENTRY_DISABLED === "true";

if (!SENTRY_DISABLED) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://a31beb1407d346368ce9da16590d0821@o1304079.ingest.sentry.io/6543835",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    integrations: [new BrowserTracing()],
    environment: process.env.COMULATE_ENV,
    beforeSend(event) {
      if (
        typeof window?.LogRocket === "object" &&
        window.LogRocket.sessionURL !== null
      ) {
        const extra = event.extra ?? {};
        extra["LogRocket"] = window.LogRocket.sessionURL;
        event.extra = extra;
      }
      return event;
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
