"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeNumberAsCell = void 0;
const encodeNumberAsCell = (num) => {
    const ordA = "a".charCodeAt(0);
    const ordZ = "z".charCodeAt(0);
    const len = ordZ - ordA + 1;
    let str = "";
    while (num >= 0) {
        str = String.fromCharCode((num % len) + ordA) + str;
        num = Math.floor(num / len) - 1;
    }
    return str.toUpperCase();
};
exports.encodeNumberAsCell = encodeNumberAsCell;
