"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getYearFromMonthIndex = void 0;
const chrono = __importStar(require("chrono-node"));
const luxon_1 = require("luxon");
/**
 * Decides year from provided month - if the month in the "current" year
 * (as provided by refDate) is more than two months into the future, we
 * assume the date is actually in the past and instead use the previous year.
 *
 * @monthIndex 0-indexed month index
 */
const getYearFromMonthIndex = (monthIndex, refDate) => {
    const currentYear = refDate.year;
    const currentYearDate = luxon_1.DateTime.local(currentYear, monthIndex + 1, 1);
    const monthDiff = currentYearDate
        .endOf("month")
        .diff(refDate.endOf("month"), "months")
        .toObject().months;
    if (monthDiff && monthDiff > 2) {
        // Use previous year if date using current year is more than two months
        // into future
        return currentYear - 1;
    }
    else {
        // Use current year
        return currentYear;
    }
};
exports.getYearFromMonthIndex = getYearFromMonthIndex;
/**
 * Our modified chrono instance for use in parsing dates in commission statements.
 * Adds/removes certain parsers for the specific behavior we want.
 *
 * Note: We exclude based on object properties instead of class names because the
 * class names are not preserved when doing a production build.
 * */
const customChrono = chrono.casual.clone();
customChrono.parsers = customChrono.parsers.filter((p) => {
    const placeholderContext = {
        text: "",
        option: {},
        refDate: new Date(),
    };
    // Exclude ENCasualDateParser
    if (typeof p === "object" &&
        typeof p.innerPattern === "function" &&
        p.innerPattern(placeholderContext) instanceof RegExp &&
        p.innerPattern(placeholderContext).source ===
            "(now|today|tonight|tomorrow|tmr|tmrw|yesterday|last\\s*night)(?=\\W|$)") {
        return false;
    }
    // Exclude ENTimeExpressionParser
    if (typeof p === "object" &&
        typeof p.primaryPrefix === "function" &&
        p.primaryPrefix() === "(?:(?:at|from)\\s*)??") {
        return false;
    }
    // Exclude SlashDateFormatParser
    if (typeof p === "object" &&
        typeof p.groupNumberMonth === "number" &&
        typeof p.groupNumberDay === "number") {
        return false;
    }
    // Exclude ENCasualTimeParser
    if (typeof p === "object" &&
        typeof p.innerPattern === "function" &&
        p.innerPattern(placeholderContext) instanceof RegExp &&
        p.innerPattern(placeholderContext).source ===
            "(?:this)?\\s{0,3}(morning|afternoon|evening|night|midnight|noon)(?=\\W|$)") {
        return false;
    }
    return true;
});
const MONTHS = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "[o0]{1}ctober",
    "n[o0]{1}vember",
    "december",
];
const MONTHS_SHORT = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "[o0]{1}ct",
    "n[o0]{1}v",
    "dec",
];
const findMatchingMonthIndex = (potentialMatch, months) => {
    return months.findIndex((m) => potentialMatch.match(m));
};
/** Parses both long and short form month name */
const MONTH_NAME_REGEX = `(${MONTHS.join("|")}|${MONTHS_SHORT.join("|")})`;
/** Parses month (1-12) with optional leading zero */
const MONTH_DIGIT_REGEX = (requiredLeadingZero = false) => `(0{${requiredLeadingZero ? "1" : "0,1"}}[1-9]|1[012])`;
/** Parses day (1-39) with optional leading zero */
const DAY_DIGIT_REGEX = (requiredLeadingZero = false) => `([0-3]{${requiredLeadingZero ? "1" : "0,1"}}[0-9]{1})`;
/** Parses four-digit year from 1970 - 2299 */
const YEAR_FOUR_DIGIT_REGEX = `([2]{1}[012]{1}[0-9]{2}|[1]{1}[9]{1}[789]{1}[0-9]{1})`;
/** Parses two-digit year from '70 - '29 */
const YEAR_TWO_DIGIT_REGEX = `([012789]{1}[0-9]{1})`;
const getMonthIndexFromMonthMatch = (match) => {
    if (findMatchingMonthIndex(match, MONTHS) !== -1) {
        return findMatchingMonthIndex(match, MONTHS);
    }
    if (findMatchingMonthIndex(match, MONTHS_SHORT) !== -1) {
        return findMatchingMonthIndex(match, MONTHS_SHORT);
    }
    throw new Error(`Could not find month match, ${JSON.stringify(match)}`);
};
const getYearFromYearMatch = (match) => {
    let year = parseInt(match);
    if (year < 100) {
        // Two-digit year, must add 2000 to get the correct year
        if (year >= 70) {
            year += 1900;
        }
        else {
            year += 2000;
        }
    }
    return year;
};
const extractMmYyFormat = (context, match) => {
    const year = getYearFromYearMatch(match[2]);
    if (year < 1970 || year > 2100) {
        return null;
    }
    return context
        .createParsingComponents()
        .assign("day", 1)
        .assign("month", parseInt(match[1]))
        .assign("year", year);
};
const customParsers = [
    /**
     * Parser for MONTH/YEAR digit formats (e.g. 9/21)
     * Supports both 4-digit and 2-digit years (assumed to be after 1970)
     * Supports a variety of different separators (e.g. /, -, ., etc.) between month and year:
     *   - If year is 4 digits, separator *is not* required
     *   - If year is 2 digits, separator *is* required if there is no leading 0
     */
    {
        pattern: () => new RegExp(`^${MONTH_DIGIT_REGEX()}[-/. ]{0,1}(${YEAR_FOUR_DIGIT_REGEX})$`, "i"),
        extract: extractMmYyFormat,
    },
    {
        pattern: () => new RegExp(`^${MONTH_DIGIT_REGEX()}[-/. ]{1}(${YEAR_TWO_DIGIT_REGEX})$`, "i"),
        extract: extractMmYyFormat,
    },
    {
        pattern: () => new RegExp(`^${MONTH_DIGIT_REGEX(true)}[-/. ]{0,1}(${YEAR_TWO_DIGIT_REGEX})$`, "i"),
        extract: extractMmYyFormat,
    },
    /** Parser for month/day-month/day/year format (e.g. 10/05-11/05/2022) with separator */
    {
        pattern: () => new RegExp(`^${MONTH_DIGIT_REGEX()}[-/. ]{1}${DAY_DIGIT_REGEX()}[-/. ]{1}${MONTH_DIGIT_REGEX()}[-/. ]{1}${DAY_DIGIT_REGEX()}[-/. ](${YEAR_FOUR_DIGIT_REGEX}|${YEAR_TWO_DIGIT_REGEX})$`, "i"),
        extract: (context, match) => {
            const year = getYearFromYearMatch(match[5]);
            if (year < 1970 || year > 2100) {
                return null;
            }
            return context
                .createParsingComponents()
                .assign("month", parseInt(match[1]))
                .assign("day", parseInt(match[2]))
                .assign("year", year);
        },
    },
    /** Parser for month/day/year format (e.g. 11/5/2022) with separator */
    {
        pattern: () => new RegExp(`([^\\d]|^)${MONTH_DIGIT_REGEX()}[-/. ]{1}${DAY_DIGIT_REGEX()}[-/. ]{1}([0-9]{2}|[0-9]{4})(\\W|$)`, "i"),
        extract: (context, match) => {
            const yearMatch = parseInt(match[4]);
            let year = yearMatch;
            if (yearMatch < 100) {
                // Two-digit year, must add 2000 to get the correct year
                year += 2000;
            }
            return context
                .createParsingComponents()
                .assign("month", parseInt(match[2]))
                .assign("day", parseInt(match[3]))
                .assign("year", year);
        },
    },
    /**
     * Parser for YEAR/MONTH format (e.g. 2021/09)
     * Supports a variety of different separators (e.g. /, -, ., etc.) between year and month
     */
    {
        pattern: () => new RegExp(`^${YEAR_FOUR_DIGIT_REGEX}[-/. ]{0,1}${MONTH_DIGIT_REGEX(true)}$`, "i"),
        extract: (context, match) => {
            return context
                .createParsingComponents()
                .assign("day", 1)
                .assign("year", parseInt(match[1]))
                .assign("month", parseInt(match[2]));
        },
    },
    /**
     * Parser for just month name long (e.g. January) or short (e.g. Jan), no year.
     * Decides between previous and current year based on which is closer to the reference date.
     *
     * Optionally allows for a single-char suffix (e.g. MAR-A) to be appended to the
     * month name to handle format we've seen where billing frequency is appended.
     */
    {
        pattern: () => new RegExp(`^${MONTH_NAME_REGEX}(-${MONTH_NAME_REGEX})?(-[A-Z])?$`, "i"),
        extract: (context, match) => {
            const monthMatch = match[1].toLowerCase();
            /** O-indexed month */
            const monthIndex = getMonthIndexFromMonthMatch(monthMatch);
            // Decide whether to use current year or previous year
            const refDate = luxon_1.DateTime.fromJSDate(context.refDate);
            const year = (0, exports.getYearFromMonthIndex)(monthIndex, refDate);
            return context
                .createParsingComponents()
                .assign("day", 1)
                .assign("year", year)
                .assign("month", monthIndex + 1);
        },
    },
    /** Parser for YearMonthDay with no separator (e.g. 20230401) */
    {
        pattern: () => new RegExp(`^${YEAR_FOUR_DIGIT_REGEX}${MONTH_DIGIT_REGEX(true)}${DAY_DIGIT_REGEX(true)}$`, "i"),
        extract: (context, match) => {
            return context
                .createParsingComponents()
                .assign("year", parseInt(match[1]))
                .assign("month", parseInt(match[2]))
                .assign("day", parseInt(match[3]));
        },
    },
    /** Parser for MonthDayYear with no separator (e.g. 10012022) */
    {
        pattern: () => new RegExp(`^${MONTH_DIGIT_REGEX(true)}${DAY_DIGIT_REGEX(true)}(${YEAR_FOUR_DIGIT_REGEX}|${YEAR_TWO_DIGIT_REGEX})(\\W|$)`, "i"),
        extract: (context, match) => {
            const year = getYearFromYearMatch(match[3]);
            if (year < 1970 || year > 2100) {
                return null;
            }
            return context
                .createParsingComponents()
                .assign("month", parseInt(match[1]))
                .assign("day", parseInt(match[2]))
                .assign("year", year);
        },
    },
    /**Parser for YearMonthNameDay with no separator (e.g. 2022MAY01) */
    {
        pattern: () => new RegExp(`^${YEAR_FOUR_DIGIT_REGEX}(${MONTHS_SHORT.map((month) => month.toUpperCase()).join("|")})${DAY_DIGIT_REGEX(true)}$`, "i"),
        extract: (context, match) => {
            const monthName = match[2].toLocaleLowerCase();
            const monthInd = findMatchingMonthIndex(monthName, MONTHS_SHORT);
            return context
                .createParsingComponents()
                .assign("year", parseInt(match[1]))
                .assign("month", monthInd + 1)
                .assign("day", parseInt(match[3]));
        },
    },
    /** Parser for day-month_name-year (e.g. 01-Aug-2022) */
    {
        pattern: () => new RegExp(`^${DAY_DIGIT_REGEX()}[-/. ]{0,1}${MONTH_NAME_REGEX}[-/. ]{0,1}([0-9]{2}|[0-9]{4})$`, "i"),
        extract: (context, match) => {
            const dayMatch = parseInt(match[1]);
            const monthMatch = match[2].toLowerCase();
            const monthIndex = getMonthIndexFromMonthMatch(monthMatch);
            const year = getYearFromYearMatch(match[3]);
            if (year < 1970 || year > 2100) {
                return null;
            }
            return context
                .createParsingComponents()
                .assign("day", dayMatch)
                .assign("month", monthIndex + 1)
                .assign("year", year);
        },
    },
    /** Parser for month_name-year (e.g. Aug-2022) */
    {
        pattern: () => new RegExp(`(${MONTH_NAME_REGEX})[-/ ]{0,1}(${YEAR_FOUR_DIGIT_REGEX}|${YEAR_TWO_DIGIT_REGEX})`, "i"),
        extract: (context, match) => {
            const monthMatch = match[1].toLowerCase();
            const monthIndex = getMonthIndexFromMonthMatch(monthMatch);
            const year = getYearFromYearMatch(match[3]);
            if (year < 1970 || year > 2100) {
                return null;
            }
            return context
                .createParsingComponents()
                .assign("day", 1)
                .assign("month", monthIndex + 1)
                .assign("year", year);
        },
    },
    /** Parser for year-month_name (e.g. 22-Aug) */
    {
        pattern: () => new RegExp(`(${YEAR_FOUR_DIGIT_REGEX}|${YEAR_TWO_DIGIT_REGEX})[-/ ]{0,1}(${MONTH_NAME_REGEX})`, "i"),
        extract: (context, match) => {
            const monthMatch = match[4].toLowerCase();
            const monthIndex = getMonthIndexFromMonthMatch(monthMatch);
            const year = getYearFromYearMatch(match[1]);
            if (year < 1970 || year > 2100) {
                return null;
            }
            return context
                .createParsingComponents()
                .assign("day", 1)
                .assign("month", monthIndex + 1)
                .assign("year", year);
        },
    },
];
customChrono.parsers = [...customParsers, ...customChrono.parsers];
// Filter out UnlikelyFormatFilter
customChrono.refiners = customChrono.refiners.filter((r) => {
    return !("strictMode" in r);
});
customChrono.refiners.push({
    /** Custom refiner to make default hour value be zero (instead of 12). Will be overriden if explicitly parsed */
    refine: (_, results) => {
        results.forEach((r) => {
            r.start.imply("hour", 0);
            r.end && r.end.imply("hour", 0);
        });
        return results;
    },
});
exports.default = customChrono;
