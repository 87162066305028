import * as React from "react";

import { MonthNumbers } from "luxon";
import CalendarMonthApr24 from "./CalendarMonthApr24";
import CalendarMonthAug24 from "./CalendarMonthAug24";
import CalendarMonthDec24 from "./CalendarMonthDec24";
import CalendarMonthFeb24 from "./CalendarMonthFeb24";
import CalendarMonthJan24 from "./CalendarMonthJan24";
import CalendarMonthJul24 from "./CalendarMonthJul24";
import CalendarMonthJun24 from "./CalendarMonthJun24";
import CalendarMonthMar24 from "./CalendarMonthMar24";
import CalendarMonthMay24 from "./CalendarMonthMay24";
import CalendarMonthNov24 from "./CalendarMonthNov24";
import CalendarMonthOct24 from "./CalendarMonthOct24";
import CalendarMonthSep24 from "./CalendarMonthSep24";

function CalendarMonth24({
  month,
  ...props
}: { month: MonthNumbers } & React.SVGProps<SVGSVGElement>) {
  switch (month) {
    case 1:
      return <CalendarMonthJan24 {...props} />;
    case 2:
      return <CalendarMonthFeb24 {...props} />;
    case 3:
      return <CalendarMonthMar24 {...props} />;
    case 4:
      return <CalendarMonthApr24 {...props} />;
    case 5:
      return <CalendarMonthMay24 {...props} />;
    case 6:
      return <CalendarMonthJun24 {...props} />;
    case 7:
      return <CalendarMonthJul24 {...props} />;
    case 8:
      return <CalendarMonthAug24 {...props} />;
    case 9:
      return <CalendarMonthSep24 {...props} />;
    case 10:
      return <CalendarMonthOct24 {...props} />;
    case 11:
      return <CalendarMonthNov24 {...props} />;
    case 12:
      return <CalendarMonthDec24 {...props} />;
  }
}

export default CalendarMonth24;
