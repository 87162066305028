import { NavItem } from "./types";

export const GLOBAL_NAV: Record<string, NavItem> = {
  DASHBOARD: { name: "Dashboard", href: "/", regex: /^\/$/ },
  DEPOSITS: { name: "Deposits", href: "/deposits", regex: /^\/deposits$/ },
  OWL: { name: "Owl", href: "/owl", regex: /^\/owl$/ },
  EARNINGS: { name: "Earnings", href: "/earnings", regex: /^\/earnings$/ },
  INSIGHTS: { name: "Insights", href: "/reports", regex: /^\/reports.*$/ },
  MORE: { name: "More", href: "/c", regex: /^\/c(\/.*)?$/ },
  PAYABLES: {
    name: "Payables",
    href: "/accounts-payable",
    regex: /^\/accounts-payable$/,
  },
  INVOICE: {
    name: "Invoices",
    href: "/invoices",
    regex: /^\/invoices$/,
  },
  LEDGER: { name: "Ledger", href: "/ledger", regex: /^\/ledger.*$/ },
  STATEMENTS: {
    name: "Statements",
    href: "/statements",
    regex: /^\/statements$/,
  },
  TRIAGE: { name: "Triage", href: "/triage", regex: /^\/triage$/ },
};
