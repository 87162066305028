import * as React from "react";

function CalendarMonthJun24(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.49023 2.55469V4.91602M17.5098 2.55469V4.91602M2.55469 19.084V7.27734C2.55469 5.97322 3.61189 4.91602 4.91602 4.91602H19.084C20.3881 4.91602 21.4453 5.97322 21.4453 7.27734V19.084M2.55469 19.084C2.55469 20.3881 3.61189 21.4453 4.91602 21.4453H19.084C20.3881 21.4453 21.4453 20.3881 21.4453 19.084M2.55469 19.084V11.2129M21.4453 19.084V11.2129"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2062 16.1477C15.2062 16.4845 15.122 16.7932 14.9536 17.0738C14.7853 17.3545 14.5607 17.579 14.2801 17.7474C13.9994 17.9158 13.6907 18 13.3539 18H10.6596C10.3228 18 10.0094 17.9158 9.71944 17.7474C9.43879 17.579 9.21426 17.3545 9.04587 17.0738C8.87748 16.7932 8.79328 16.4845 8.79328 16.1477V10.3802C8.79328 10.0341 8.87748 9.72069 9.04587 9.44004C9.21426 9.15938 9.43879 8.93486 9.71944 8.76647C10.0094 8.59808 10.3228 8.51388 10.6596 8.51388H13.3539C13.6907 8.51388 13.9994 8.59808 14.2801 8.76647C14.5607 8.93486 14.7853 9.15938 14.9536 9.44004C15.122 9.72069 15.2062 10.0341 15.2062 10.3802V10.7311C15.2062 10.9088 15.1407 11.0632 15.0098 11.1941C14.8882 11.3251 14.7385 11.3906 14.5607 11.3906H14.1818C14.0041 11.3906 13.8497 11.3251 13.7188 11.1941C13.5971 11.0632 13.5363 10.9088 13.5363 10.7311V10.3802C13.5363 10.2493 13.4755 10.1838 13.3539 10.1838H10.6596C10.6129 10.1838 10.5661 10.2025 10.5193 10.2399C10.4819 10.2773 10.4632 10.3241 10.4632 10.3802V11.8677C10.4632 11.9893 10.524 12.0501 10.6456 12.0501H13.3539C13.6907 12.0501 13.9994 12.1343 14.2801 12.3027C14.5607 12.4618 14.7853 12.6816 14.9536 12.9623C15.122 13.2429 15.2062 13.5563 15.2062 13.9024V16.1477ZM13.5363 16.1477V13.9024C13.5363 13.7715 13.4755 13.706 13.3539 13.706H10.6456C10.524 13.706 10.4632 13.7668 10.4632 13.8884V16.1477C10.4632 16.1945 10.4819 16.2412 10.5193 16.288C10.5661 16.3254 10.6129 16.3441 10.6596 16.3441H13.3539C13.4007 16.3441 13.4428 16.3254 13.4802 16.288C13.5176 16.2412 13.5363 16.1945 13.5363 16.1477Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CalendarMonthJun24;
