import { isNullOrUndefined } from "./formatting";
import logger from "./logger";

/**
 * WARNING:
 * This type must be kept in sync with the type in the frontend/webextUtils.ts
 * file. If you change one, *you must copy paste this type to the other file*.
 * This is so that messages between the frontend and web extension are type
 * safe.
 */
type WebextOperations = {
  ExtensionEnabled: {
    request: Record<string, never>;
    response: {
      enabled: boolean;
    };
  };
  IsUserLoggedIn: {
    request: Record<string, never>;
    response: {
      isLoggedIn: boolean;
    };
  };
  AuthenticateUser: {
    request: Record<string, never>;
    response: {
      ok: true;
    };
  };
  OpenVaultAndAutofill: {
    request: {
      loginUrl: string;
      vaultCredentialId: string;
    };
    response: {
      ok: true;
    };
  };
  InitiateEpicHeadlessPosting: {
    request: {
      outboundStatementId: string;
      defaultExternalGeneralLedgerUrl: string;
    };
    response: {
      ok: true;
    };
  };
  AttemptAMS360HeadlessPosting: {
    request: {
      outboundStatementId: string;
    };
    response: {
      ok: boolean;
    };
  };
  AttemptAMS360HeadlessReceiptPosting: {
    request: {
      cashReceivedBatchId: string;
      cashReceivedPaymentIds: string[];
    };
    response: {
      ok: boolean;
    };
  };
};

export type WebextMessage<T extends keyof WebextOperations> = {
  type: T;
  request: WebextOperations[T]["request"];
};

/**
 * Lets the frontend send messages to the web-extension
 */
export const sendWebextMessage = async <T extends keyof WebextOperations>(
  message: WebextMessage<T>
): Promise<WebextOperations[T]["response"]> => {
  const webextId = process.env.NEXT_PUBLIC_WEBEXT_EXTENSION_ID;

  if (isNullOrUndefined(webextId)) {
    throw new Error(
      "Cannot send message to extension, extension ID is missing from env"
    );
  }

  const extensionEnabled = await isExtensionEnabled();

  if (!extensionEnabled) {
    throw new Error(
      "Cannot send message to extension, extension is not enabled"
    );
  }

  if (message.type === "AuthenticateUser") {
    return await chrome.runtime.sendMessage(webextId, {
      type: message.type,
      request: message.request,
    });
  }

  const extensionAuthenticated = await isExtensionLoggedIn();

  if (!extensionAuthenticated) {
    throw new Error("Cannot send message to extension, user is not logged in");
  }

  // Timeout is temporary, please remove after
  // https://app.asana.com/0/1206401514290263/1207093999626491/f
  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => reject(new Error("Timeout after 2 seconds")), 2000)
  );

  return Promise.race([
    chrome.runtime.sendMessage(webextId, {
      type: message.type,
      request: message.request,
    }),
    timeoutPromise,
  ]);
};

export const isExtensionEnabled = async (): Promise<boolean> => {
  // check if chromium based browser
  if (typeof chrome === "undefined") return false;
  if (!chrome?.runtime) return false;
  const webextId = process.env.NEXT_PUBLIC_WEBEXT_EXTENSION_ID;
  try {
    const response = await chrome.runtime.sendMessage(webextId, {
      type: "ExtensionEnabled",
      request: {},
    });

    return response.enabled;
  } catch (e) {
    logger.info(e);
    logger.info("Extension probably not installed-- enable check failed");
    return false;
  }
};

export const isExtensionLoggedIn = async (): Promise<boolean> => {
  const webextId = process.env.NEXT_PUBLIC_WEBEXT_EXTENSION_ID;
  const response = await chrome.runtime.sendMessage(webextId, {
    type: "IsUserLoggedIn",
    request: {},
  });
  return response.isLoggedIn;
};

export const isExtensionEnabledAndLoggedIn = async (): Promise<boolean> => {
  return (await isExtensionEnabled()) && (await isExtensionLoggedIn());
};

export const triggerHeadlessEpicPosting = async (
  outboundStatementId: string,
  defaultExternalGeneralLedgerUrl: string
): Promise<void> => {
  await sendWebextMessage({
    type: "InitiateEpicHeadlessPosting",
    request: {
      outboundStatementId,
      defaultExternalGeneralLedgerUrl,
    },
  });
};

export const attemptHeadlessAMS360Posting = async (
  outboundStatementId: string
) => {
  return await sendWebextMessage({
    type: "AttemptAMS360HeadlessPosting",
    request: {
      outboundStatementId,
    },
  });
};

export const attemptAMS360HeadlessReceiptPosting = async ({
  cashReceivedBatchId,
  cashReceivedPaymentIds,
}: {
  cashReceivedBatchId: string;
  cashReceivedPaymentIds: string[];
}) => {
  return await sendWebextMessage({
    type: "AttemptAMS360HeadlessReceiptPosting",
    request: {
      cashReceivedBatchId,
      cashReceivedPaymentIds,
    },
  });
};
