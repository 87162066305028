import { assertDefined } from "../../src/utils";
import {
  formatCashReceivedBatchName,
  formatRelativeTimestamp,
} from "../../src/formatting";
import Link from "next/link";
import { CashReceivedBatchProcessedNotificationPayload } from "../../src/generated/graphql";

export const CashReceivedBatchProcessedNotification = ({
  payload,
  createdAt,
}: {
  payload: CashReceivedBatchProcessedNotificationPayload;
  createdAt: string;
}) => {
  assertDefined(payload.cashReceivedBatch);

  return (
    <Link
      className="block"
      href={`/deposits?batchId=${payload.cashReceivedBatch.id}`}
    >
      <div className="py-3 px-4 flex space-x-3 hover:bg-zinc-50">
        <div className="shrink-0">
          <img src="/comulate_logo.png" className="w-6 h-6 rounded-full" />
        </div>
        <div className="space-y-1">
          <div className="text-sm text-zinc-500">
            <span className="font-medium text-zinc-900">Comulate</span>{" "}
            processed batch deposit{" "}
            <span className="font-medium text-green-900">
              {formatCashReceivedBatchName(payload.cashReceivedBatch)}
            </span>
          </div>
          <div className="text-xs leading-none font-normal text-zinc-500">
            {formatRelativeTimestamp(createdAt)}
          </div>
        </div>
      </div>
    </Link>
  );
};
