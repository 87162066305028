import { useImportPeriodQuery } from "../src/generated/graphql";

export default function useImportPeriod() {
  const { data, refetch } = useImportPeriodQuery();

  return {
    importPeriod: data?.user?.selectedPeriod || data?.openPeriod,
    refetch,
  };
}
