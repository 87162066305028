import { useCallback } from "react";

/**
 * Note: when using this hook, be sure to update the InMemoryCache in _app.tsx to
 * ensure that the paginated results of your query are merged by Apollo correctly.
 * If that is not updated – or if your query does not conform to Relay pagination
 * standards – Apollo will replace the cached page with the incoming one, instead
 * of merging the results.
 */
export const useRelayStyleFetchMore = (
  skipFetchingNextPage: boolean,
  fetchMore: ({ variables: { after } }) => void,
  pageInfo?: { endCursor?: string | null; hasNextPage?: boolean }
) => {
  return useCallback(async () => {
    if (skipFetchingNextPage) return;

    const after = pageInfo?.endCursor ? parseInt(pageInfo.endCursor) : 0;

    if (after && pageInfo?.hasNextPage) {
      await fetchMore({
        variables: { after },
      });
    }
  }, [
    skipFetchingNextPage,
    pageInfo?.endCursor,
    pageInfo?.hasNextPage,
    fetchMore,
  ]);
};
