import * as React from "react";

function CalendarMonthMay24(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.49023 2.55469V4.91602M17.5098 2.55469V4.91602M2.55469 19.084V7.27734C2.55469 5.97322 3.61189 4.91602 4.91602 4.91602H19.084C20.3881 4.91602 21.4453 5.97322 21.4453 7.27734V19.084M2.55469 19.084C2.55469 20.3881 3.61189 21.4453 4.91602 21.4453H19.084C20.3881 21.4453 21.4453 20.3881 21.4453 19.084M2.55469 19.084V11.2129M21.4453 19.084V11.2129"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2062 9.53827C15.2062 9.73473 15.1407 9.88909 15.0098 10.0013C14.8882 10.123 14.7385 10.1838 14.5607 10.1838H10.6456C10.524 10.1838 10.4632 10.2446 10.4632 10.3662V11.8537C10.4632 11.9846 10.524 12.0501 10.6456 12.0501H13.3539C13.7001 12.0501 14.0088 12.1343 14.2801 12.3027C14.5607 12.4711 14.7853 12.6956 14.9536 12.9763C15.122 13.2476 15.2062 13.5563 15.2062 13.9024V16.1477C15.2062 16.4845 15.122 16.7932 14.9536 17.0738C14.7853 17.3545 14.5607 17.579 14.2801 17.7474C13.9994 17.9158 13.6907 18 13.3539 18H10.6596C10.3228 18 10.0094 17.9158 9.71944 17.7474C9.43879 17.579 9.21426 17.3545 9.04587 17.0738C8.87748 16.7932 8.79328 16.4845 8.79328 16.1477V15.1373C8.79328 14.9596 8.85409 14.8099 8.97571 14.6883C9.10668 14.5573 9.26104 14.4918 9.43879 14.4918H9.8317C10.0001 14.4918 10.1451 14.5573 10.2667 14.6883C10.3977 14.8099 10.4632 14.9596 10.4632 15.1373V16.1477C10.4632 16.1945 10.4819 16.2412 10.5193 16.288C10.5661 16.3254 10.6129 16.3441 10.6596 16.3441H13.3539C13.4007 16.3441 13.4428 16.3254 13.4802 16.288C13.5176 16.2412 13.5363 16.1945 13.5363 16.1477V13.9024C13.5363 13.8557 13.5176 13.8136 13.4802 13.7762C13.4428 13.7294 13.4007 13.706 13.3539 13.706H9.43879C9.25168 13.706 9.102 13.6405 8.98974 13.5095C8.85877 13.3973 8.79328 13.2476 8.79328 13.0605V9.15938C8.79328 8.97228 8.85409 8.81792 8.97571 8.6963C9.10668 8.57469 9.26104 8.51388 9.43879 8.51388H14.5607C14.7385 8.51388 14.8882 8.57469 15.0098 8.6963C15.1407 8.81792 15.2062 8.97228 15.2062 9.15938V9.53827Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CalendarMonthMay24;
