import React from "react";
import { classNames } from "../src/formatting";

export interface SelectButtonElementProps {
  line1Text: string;
  line2Text?: string;
  variant?: "normal" | "dimmed";
  size?: "xs" | "small";
}
export const SelectButtonElement = (props: SelectButtonElementProps) => (
  <div className="flex flex-col space-y-0.5">
    <span
      className={classNames(
        "font-normal leading-5 truncate",
        props.variant === "dimmed" ? "text-zinc-500" : "text-zinc-900",
        props.size === "xs"
          ? "text-xs"
          : props.size === "small"
          ? "text-sm"
          : ""
      )}
    >
      {props.line1Text}
    </span>
    {props.line2Text && (
      <span className="text-zinc-500 text-xs font-normal leading-4 break-all truncate">
        {props.line2Text}
      </span>
    )}
  </div>
);
