import { useAuth0 } from "@auth0/auth0-react";
import { useUserAvatarUrls } from "../../hooks/useUserAvatarUrls";
import { useLoggedInUser } from "../LoggedInUserProvider";
import { classNames } from "../../src/formatting";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarSection,
} from "../../core/Navbar";
import { Link } from "../../core/Link";
import ComulateBrandMark from "../icons/ComulateBrandMark";
import { useRouter } from "next/router";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import NotificationsMenu from "../../apps/Notifications";
import Menu from "../Menu";
import useNavigationItems from "./hooks/useNavigationItems";
import { Fragment } from "react";
import PeriodSelector from "./PeriodSelector";
import { UserRole } from "@comulate/graphql-types";

export const Appbar = () => {
  const router = useRouter();
  const navItems = useNavigationItems();

  const { logout } = useAuth0();
  const { user } = useLoggedInUser();
  const { avatarUrlForUserId } = useUserAvatarUrls();

  const periodSelectorInAppBar =
    user?.role && [UserRole.ADMIN, UserRole.STANDARD].includes(user.role);

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <Navbar className="group z-10 bg-zinc-100 border-b border-zinc-200 shadow-inner-navbar h-14">
      <NavbarSection className="max-w-8xl mx-auto px-4 w-full justify-between overflow-x-auto overflow-y-hidden -my-2.5 py-2.5">
        <NavbarSection>
          <div className="flex-shrink-0 flex items-center px-2">
            <Link href="/">
              <ComulateBrandMark className="text-zinc-700 fade-in-out" />
            </Link>
          </div>
          {navItems.map((section, sectionInd) => (
            <Fragment key={sectionInd}>
              <div className="flex gap-2">
                {section.map(({ name, href, regex }) => {
                  const current = regex.test(router.pathname);

                  return (
                    <NavbarItem
                      key={name}
                      href={href}
                      current={current}
                      className={classNames(
                        current
                          ? "text-green-900"
                          : "fade-in-out text-zinc-700",
                        "text-sm font-medium"
                      )}
                    >
                      {name}
                    </NavbarItem>
                  );
                })}
              </div>
              {sectionInd !== navItems.length - 1 && (
                <NavbarDivider key={sectionInd} />
              )}
            </Fragment>
          ))}
        </NavbarSection>
        <NavbarSection className="!gap-1">
          <NavbarItem
            key="help"
            href="https://q.comulate.com"
            target="_blank"
            className="fade-in-out"
          >
            <QuestionMarkCircleIcon className="h-6 w-6 text-zinc-500" />
          </NavbarItem>
          {user && !hideNotifications(user.role) && (
            <NotificationsMenu as={NavbarItem as any} className="fade-in-out" />
          )}
          {periodSelectorInAppBar && <PeriodSelector />}
          <div className="shrink-0">
            <Menu
              width={196}
              button={
                <Menu.Button as={NavbarItem} className="fade-in-out">
                  <div className="bg-zinc-800 flex text-sm rounded-full">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-6 w-6 rounded-full -outline-offset-1 outline outline-zinc-700/20"
                      src={avatarUrlForUserId(user?.id ?? "")}
                      alt="avatar"
                    />
                  </div>
                </Menu.Button>
              }
            >
              <Menu.Group>
                <Menu.Action href="/settings/user-profile">
                  Settings
                </Menu.Action>
                <Menu.Action
                  onClick={(event) => {
                    event.preventDefault();

                    logout({
                      returnTo: window.location.origin,
                    });
                  }}
                >
                  Log out
                </Menu.Action>
              </Menu.Group>
            </Menu>
          </div>
        </NavbarSection>
      </NavbarSection>
    </Navbar>
  );
};

function hideNotifications(role: UserRole): boolean {
  const noAccess = [UserRole.TRIAGER];

  return noAccess.includes(role);
}
