import { DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { classNames } from "../src/formatting";
import Modal from "./Modal";

/**
 * Modal with a standardized layout of title, body, and footer.
 */
export default function ModalWithContent({
  title,
  body,
  footer,
  open,
  setOpen,
  showCloseButton = false,
  wrapperClasses = "",
}: {
  title: string | JSX.Element;
  body: JSX.Element;
  footer: JSX.Element | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
  showCloseButton?: boolean;
  wrapperClasses?: string;
}) {
  return (
    <Modal open={open} setOpen={setOpen} doNotPad>
      <div className={classNames(wrapperClasses)}>
        <div className="flex mb-2 px-6 py-5 items-center justify-between flex-wrap sm:flex-nowrap border-b border-zinc-200">
          <DialogTitle
            as="h2"
            className="text-lg leading-6 font-medium text-zinc-900"
          >
            {title}
          </DialogTitle>

          {showCloseButton && (
            <button
              type="button"
              className="rounded-md bg-white text-zinc-400 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              onClick={() => setOpen(false)}
            >
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          )}
        </div>

        <div className="flex flex-col items-start px-6 py-6">{body}</div>

        {footer && (
          <div className="space-x-2 px-6 py-4 border-t border-zinc-200 flex items-center justify-end flex-wrap sm:flex-nowrap bg-zinc-50 rounded-b-lg">
            {footer}
          </div>
        )}
      </div>
    </Modal>
  );
}
