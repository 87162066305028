import { logEvent } from "./common";

export type PageCrashLoggingEvent = "Error Fallback Mounted";
export const logPageCrash = (
  name: string | undefined | null,
  message: string | undefined | null
) =>
  logEvent("Error Fallback Mounted", {
    url: window.location.href,
    name,
    message,
  });
