"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CLIENT_NAME_WORDS_TO_STRIP = void 0;
/** A list of filler words to strip out client names for accurate comparison.
 *
 * IMPORTANT!!!! Modifying ANY value in this file must be accompanied by a migration.
 *
 * That migration should replace the functions called by the database triggers
 * created originally in CreateAutoFilledPolicyNumberAndClientColumns1697818229468.
 * Following that, please make note of the new migration in this comment so that it
 * can be used for reference in the future.
 *
 * Separately, for the change to take backwards effect, you will need to run the
 * migration located at backfillAutoFilledPolicyColumns.ts.
 */
exports.CLIENT_NAME_WORDS_TO_STRIP = [
    "and",
    "assn",
    "association",
    "co",
    "company",
    "corp",
    "corporation",
    "dba",
    "enterprises",
    "inc",
    "incorporated",
    "llc",
    "lp",
    "ltd",
    "pllp",
    "the",
];
