import userflow from "userflow.js";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { createContext, useContext, useEffect } from "react";
import { GetUserQuery, useGetUserQuery } from "../src/generated/graphql";
import logger from "../src/logger";
import useAddFeatureFlagContext from "../feature-flags/useAddFeatureFlagContext";
import { LDContext } from "launchdarkly-react-client-sdk";

type User = GetUserQuery["user"];
type Organization = GetUserQuery["organization"];

export type UserContextType = {
  user?: User;
  organization?: Organization;
  isLoading: boolean;
  isError: boolean;
};

export const UserContext = createContext<UserContextType>({
  user: undefined,
  organization: undefined,
  isLoading: true,
  isError: false,
});

export function LoggedInUserProvider({ children }) {
  const { isLoading: authLoading, isAuthenticated } = useAuth0();

  const { data, loading, error } = useGetUserQuery({
    skip: authLoading || !isAuthenticated,
  });

  const addFeatureFlagContext = useAddFeatureFlagContext(); // Log feature flag context for a user

  useEffect(() => {
    if (!data) return;

    Sentry.setUser({
      id: data.user.id,
      email: data.user.email,
      organizationName: data.organization.name,
      organizationId: data.organization.id,
    });
    Sentry.setTag("user_email", data.user.email);
    Sentry.setTag("org_name", data.organization.name);

    const FeatureFlagContext: LDContext = {
      kind: "multi",
      user: {
        key: data.user.id,
        name: data.user.fullName,
        email: data.user.email,
      },
      organization: {
        key: data.organization.id,
        name: data.organization.name,
        isDemo: data.organization.flags.isDemo,
      },
    };

    if (addFeatureFlagContext) {
      addFeatureFlagContext(FeatureFlagContext);
    }

    userflow.init(process.env.NEXT_PUBLIC_USERFLOW_TOKEN ?? "");
    userflow.identify(data.user.id, {
      name: data.user.fullName,
      email: data.user.email,
      organizationName: data.organization.name,
      organizationId: data.organization.id,
      signed_up_at: data.user.createdAt,
    });
    userflow.group(data.organization.id, {
      name: data.organization.name,
      isDemo: data.organization.flags.isDemo,
      signedUpAt: data.organization.createdAt,
    });

    // Guard against adblockers.
    if (typeof global.analytics?.identify === "function") {
      const currentPeriod = data.user.selectedPeriod ?? data.openPeriod;

      global.analytics.identify(data.user.id, {
        email: data.user.email,
        organizationName: data.organization.name,
        organizationId: data.organization.id,
      });

      // This attaches the currentPeriod to the global analytics object so that it's accessible when sending Segment events
      global.analytics.currentPeriod = currentPeriod;
    } else {
      logger.info(
        "Skipping segment API `identify` call, because it is not initialized. One cause of this might be a user's ad-blocker."
      );
    }
  }, [addFeatureFlagContext, data]);

  return (
    <UserContext.Provider
      value={{
        user: data?.user,
        organization: data?.organization,
        isLoading: loading,
        isError: error !== undefined,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useLoggedInUser() {
  return useContext(UserContext);
}
