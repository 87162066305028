import {
  NotificationsScope,
  useNumUnreadNotificationsQuery,
} from "../../src/generated/graphql";
import { classNames } from "../../src/formatting";
import { ReactNode, useState } from "react";
import { BellIcon } from "@heroicons/react/24/outline";
import { MenuButton, MenuButtonProps } from "@headlessui/react";
import ms from "ms";
import { NotificationsPanel } from "./NotificationsPanel";
import Menu from "../../components/Menu";

export const Notifications = (buttonProps: MenuButtonProps) => {
  const { data: numUnreadNotificationsData } = useNumUnreadNotificationsQuery({
    pollInterval: ms("5 minutes"),
  });

  const [selectedTab, setSelectedTab] = useState<"for_me" | "all">("for_me");

  return (
    <Menu
      button={
        <MenuButton {...buttonProps}>
          <span className="sr-only">Open notifications menu</span>
          <BellIcon className="h-6 w-6 text-zinc-500" />
          {numUnreadNotificationsData?.numUnreadNotifications ? (
            <div className="bg-green-600 w-2.5 h-2.5 absolute right-1.5 bottom-1.5 border border-white rounded-full" />
          ) : null}
        </MenuButton>
      }
      menuItemsProps={{ unmount: false }}
    >
      {({ open, close }) => (
        <div>
          <div className="px-4 pt-4 flex space-x-3 border-b">
            <Tab
              isSelected={selectedTab === "for_me"}
              onClick={() => setSelectedTab("for_me")}
            >
              For Me
            </Tab>
            <Tab
              isSelected={selectedTab === "all"}
              onClick={() => setSelectedTab("all")}
            >
              All
            </Tab>
          </div>
          <div>
            <div className={classNames(selectedTab !== "for_me" && "hidden")}>
              <NotificationsPanel
                open={open && selectedTab === "for_me"}
                scope={NotificationsScope.USER}
                onNotificationClick={close}
              />
            </div>
            <div className={classNames(selectedTab !== "all" && "hidden")}>
              <NotificationsPanel
                open={open && selectedTab === "all"}
                scope={NotificationsScope.ORGANIZATION}
                onNotificationClick={close}
              />
            </div>
          </div>
        </div>
      )}
    </Menu>
  );
};

const Tab = ({
  isSelected,
  onClick,
  children,
}: {
  isSelected: boolean;
  onClick: () => void;
  children: ReactNode;
}) => (
  <div
    className={classNames(
      "text-sm leading-5 font-medium px-1 pb-2 border-b-2 cursor-pointer",
      isSelected
        ? "border-green-700 text-green-700"
        : "border-transparent text-zinc-500"
    )}
    onClick={onClick}
  >
    {children}
  </div>
);
