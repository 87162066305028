import * as React from "react";

function CalendarMonthApr24(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.49023 2.55469V4.91602M17.5098 2.55469V4.91602M2.55469 19.084V7.27734C2.55469 5.97322 3.61189 4.91602 4.91602 4.91602H19.084C20.3881 4.91602 21.4453 5.97322 21.4453 7.27734V19.084M2.55469 19.084C2.55469 20.3881 3.61189 21.4453 4.91602 21.4453H19.084C20.3881 21.4453 21.4453 20.3881 21.4453 19.084M2.55469 19.084V11.2129M21.4453 19.084V11.2129"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2203 15.5302C15.2203 15.6986 15.1548 15.8483 15.0238 15.9793C14.9022 16.1009 14.7525 16.1617 14.5748 16.1617H14.252C14.121 16.1617 14.0556 16.2225 14.0556 16.3441V17.3545C14.0556 17.5322 13.9947 17.6866 13.8731 17.8176C13.7515 17.9392 13.6018 18 13.4241 18H13.0452C12.8674 18 12.7131 17.9392 12.5821 17.8176C12.4605 17.6866 12.3997 17.5322 12.3997 17.3545V16.3441C12.3997 16.2225 12.3389 16.1617 12.2173 16.1617H9.42475C9.247 16.1617 9.09264 16.1009 8.96167 15.9793C8.84006 15.8483 8.77925 15.6986 8.77925 15.5302V13.7621C8.77925 13.3318 8.87748 12.9202 9.07393 12.5272L10.2246 10.3381C10.3462 10.0949 10.538 9.97328 10.8 9.97328H11.2209C11.4642 9.97328 11.6466 10.0762 11.7682 10.282C11.8992 10.4785 11.9085 10.689 11.7963 10.9135L10.5614 13.299C10.4866 13.4674 10.4491 13.6218 10.4491 13.7621V14.3234C10.4491 14.4357 10.5146 14.4918 10.6456 14.4918H12.2173C12.3389 14.4918 12.3997 14.4357 12.3997 14.3234V9.15938C12.3997 8.97228 12.4605 8.81792 12.5821 8.6963C12.7131 8.57469 12.8674 8.51388 13.0452 8.51388H13.4241C13.6018 8.51388 13.7515 8.57469 13.8731 8.6963C13.9947 8.81792 14.0556 8.97228 14.0556 9.15938V14.3234C14.0556 14.4357 14.121 14.4918 14.252 14.4918H14.5748C14.7525 14.4918 14.9022 14.5573 15.0238 14.6883C15.1548 14.8099 15.2203 14.9596 15.2203 15.1373V15.5302Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CalendarMonthApr24;
