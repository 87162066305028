import { DepositUrlPayment, getDepositUrl } from "../../../src/urlUtils";
import MenuAction from "./MenuAction";

const DepositLinkAction = ({
  cashReceivedPayment,
}: {
  cashReceivedPayment?: DepositUrlPayment | null;
}) => {
  const depositUrl = cashReceivedPayment
    ? getDepositUrl(cashReceivedPayment)
    : null;

  return (
    <MenuAction
      openInNewTab
      href={depositUrl}
      disabledTooltipMessage={
        !depositUrl ? "This Deposit was processed outside of Comulate" : null
      }
    >
      View Deposit
    </MenuAction>
  );
};

export default DepositLinkAction;
