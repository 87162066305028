"use strict";
/**
 * Shared copy for batch deposit (CRB/CRP) posting errors. Used by the frontend
 * to display error information to the user in app.
 * */
Object.defineProperty(exports, "__esModule", { value: true });
exports.depositBatchVendorErrorCodeMapping = exports.depositBatchVendorErrorCode = void 0;
/**
 * Error codes uniquely identify a deposit batch posting error with shared copy
 **/
var depositBatchVendorErrorCode;
(function (depositBatchVendorErrorCode) {
    depositBatchVendorErrorCode["EPIC_PENDING_TRANSACTIONS"] = "EPIC_PENDING_TRANSACTIONS";
    depositBatchVendorErrorCode["EPIC_UPDATED_BY_OTHER_USER"] = "EPIC_UPDATED_BY_OTHER_USER";
    depositBatchVendorErrorCode["EPIC_PERIOD_CLOSED"] = "EPIC_PERIOD_CLOSED";
    depositBatchVendorErrorCode["EPIC_CREDITS_DIFFER_FROM_AVAILABLE"] = "EPIC_CREDITS_DIFFER_FROM_AVAILABLE";
    depositBatchVendorErrorCode["EPIC_SYSTEM_UNAVAILABLE"] = "EPIC_SYSTEM_UNAVAILABLE";
    depositBatchVendorErrorCode["EPIC_INVALID_OBJECT_FOR_BANK_AGENCY"] = "EPIC_INVALID_OBJECT_FOR_BANK_AGENCY";
    depositBatchVendorErrorCode["REQUIRES_STRUCTURE_TAG"] = "REQUIRES_STRUCTURE_TAG";
    depositBatchVendorErrorCode["EPIC_WRONG_CLIENT_TRANSACTIONS"] = "EPIC_WRONG_CLIENT_TRANSACTIONS";
})(depositBatchVendorErrorCode || (exports.depositBatchVendorErrorCode = depositBatchVendorErrorCode = {}));
// TODO: const portalBaseUrl = "https://q.comulate.com/comulate-help-center/more/other";
// TODO: const epicPortalBaseUrl = `${portalBaseUrl}/epic-integrations/epic-batch-posting-errors`;
/** Define the standard user-facing title for an error, as well as the URL for
 * troubleshooting information. */
exports.depositBatchVendorErrorCodeMapping = {
    EPIC_PENDING_TRANSACTIONS: {
        title: "Transaction(s) are attached to pending processes",
        // TODO: url: `${epicPortalBaseUrl}#accounting-month-is-locked-posted-or-finalized`,
    },
    EPIC_UPDATED_BY_OTHER_USER: {
        title: "Receipt updated while posting",
        detailMessage: "This receipt was updated by another user while we were posting it. Please try again.",
        // TODO: url: `${epicPortalBaseUrl}#accounting-month-is-locked-posted-or-finalized`,
    },
    EPIC_PERIOD_CLOSED: {
        title: "Cannot post to closed period",
        detailMessage: "Please re-open the accounting month in Epic or email us at help@comulate.com to change the period for this batch.",
        // TODO: url: `${epicPortalBaseUrl}#accounting-month-is-locked-posted-or-finalized`,
    },
    EPIC_CREDITS_DIFFER_FROM_AVAILABLE: {
        title: "Credits changed while posting",
        detailMessage: "The credits available in Epic changed during posting. Please try again.",
    },
    EPIC_SYSTEM_UNAVAILABLE: {
        title: "Epic system unavailable",
        detailMessage: "The Epic system was unavailable when posting. Please try again.",
    },
    EPIC_INVALID_OBJECT_FOR_BANK_AGENCY: {
        title: "Matched object(s) not in bank account's agency",
        detailMessage: "Please ensure all matched objects (e.g., client, policy, etc.) are within the bank account's agency.",
    },
    REQUIRES_STRUCTURE_TAG: {
        title: "Requires structure tag",
        detailMessage: "This payment must be tagged with a single branch tag to post.",
    },
    EPIC_WRONG_CLIENT_TRANSACTIONS: {
        title: "Below transaction(s) cannot be applied to this client, please apply to client account instead",
    },
};
