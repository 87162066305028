import { createContext, useCallback, useContext } from "react";
import { UserAvatarQuery, useUserAvatarQuery } from "../src/generated/graphql";
import ms from "ms";

/**
 * This hook provides a global cache for user avatar URLs. It is refreshed
 * every `AVATAR_URL_POLL_INTERVAL_MS` milliseconds.
 *
 * This cache is necessary because user avatar URLs are fetched via AWS
 * signed URLs [1], so the browser cannot cache the requests even though
 * the underlying assets are the same. Without this, there is a "flash"
 * of user profile pictures whenever the asset is re-fetched.
 *
 * [1] https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/private-content-signed-urls.html
 */

/**
 * Poll every hour. This value must be less than or equal to the TTL
 * of the AWS presigned URLs, which is currently one week.
 */
export const AVATAR_URL_POLL_INTERVAL_MS = ms("1 hour");

type User = UserAvatarQuery["users"][number];

export type AllUsersContextType = {
  users?: User[];
  isLoading: boolean;
};

export const AllUsersContext = createContext<AllUsersContextType>({
  users: undefined,
  isLoading: true,
});

export function UserAvatarUrlsProvider({ children }) {
  const { data, loading } = useUserAvatarQuery({
    pollInterval: AVATAR_URL_POLL_INTERVAL_MS,
  });

  return (
    <AllUsersContext.Provider
      value={{
        users: data?.users,
        isLoading: loading,
      }}
    >
      {children}
    </AllUsersContext.Provider>
  );
}

export function useUserAvatarUrls() {
  const { users, isLoading } = useContext(AllUsersContext);

  const avatarUrlForUserId = useCallback(
    (userId: string): string | undefined => {
      return isLoading
        ? undefined
        : users?.find((user) => user.id === userId)?.avatarFile.accessUrl;
    },
    [users, isLoading]
  );

  return { avatarUrlForUserId };
}
