"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HyperFormulaCustomTranslations = exports.HyperFormulaCustom = void 0;
const hyperformula_1 = require("hyperformula");
const utils_1 = require("../import/utils");
const luxon_1 = require("luxon");
const _ = __importStar(require("lodash"));
class HyperFormulaCustom extends hyperformula_1.FunctionPlugin {
    parseDate(ast, state) {
        return this.runFunction(ast.args, state, this.metadata("PARSE_DATE"), (value) => {
            const parsedValue = (0, utils_1.parseDate)(value.toString(), true);
            if (!parsedValue)
                return "";
            return luxon_1.DateTime.fromJSDate(parsedValue).toFormat("M/d/yy");
        });
    }
    concatenate(ast, state) {
        return this.runFunction(ast.args, state, this.metadata("CONCATENATE"), (...args) => {
            return args
                .filter((arg) => typeof arg !== "symbol")
                .map((arg) => arg === null || arg === void 0 ? void 0 : arg.toString())
                .filter((value) => !(0, utils_1.isEmptyValue)(value))
                .join(" ");
        });
    }
}
exports.HyperFormulaCustom = HyperFormulaCustom;
HyperFormulaCustom.implementedFunctions = {
    PARSE_DATE: {
        method: "parseDate",
        parameters: [
            {
                argumentType: hyperformula_1.FunctionArgumentType.ANY,
            },
        ],
    },
    CONCATENATE: {
        method: "concatenate",
        parameters: _.range(30).map(() => ({
            argumentType: hyperformula_1.FunctionArgumentType.ANY,
            optionalArg: true,
        })),
    },
};
exports.HyperFormulaCustomTranslations = {
    enGB: {
        PARSE_DATE: "PARSE_DATE",
    },
};
