"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JSONParser = void 0;
class JSONParser {
    constructor(data) {
        this.data = data;
    }
    getRowValues(rowIndex) {
        return Object.values(this.data[rowIndex]);
    }
    getColumnValue(rowIndex, columnName) {
        return this.data[rowIndex][columnName];
    }
    getRowIndices() {
        return this.data.map((_, rowIndex) => rowIndex);
    }
    getColumnNames() {
        return Object.keys(this.data[0]);
    }
}
exports.JSONParser = JSONParser;
