import Tippy from "@tippyjs/react";
import { classNames, isNullOrUndefined } from "../../../src/formatting";
import { Menu } from "@headlessui/react";
import { MouseEventHandler, useContext } from "react";
import React from "react";
import Link from "next/link";
import { MenuContext } from "../Menu";
import { CheckIcon } from "@heroicons/react/20/solid";
import logger from "../../../src/logger";

type ActionRenderPropArg = {
  active: boolean;
  disabled: boolean;
  close: () => void;
};

export type MenuActionProps = {
  children:
    | string
    | React.ReactNode
    | ((props: ActionRenderPropArg) => React.ReactNode);
  subTitle?: string;
  danger?: boolean;
  loading?: boolean;
  selected?: boolean;
  disabled?: boolean;
  disabledTooltipMessage?: string | null;
  href?: string | null;
  openInNewTab?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

type MenuActionContentProps = {
  active: boolean;
  children: React.ReactNode;
} & Omit<MenuActionProps, "children">;

const MenuActionContent = React.forwardRef<
  HTMLDivElement,
  MenuActionContentProps
>(
  (
    {
      active,
      danger,
      loading,
      selected,
      onClick,
      disabledTooltipMessage,
      disabled,
      children,
      subTitle,
    },
    ref
  ) => {
    const { showSelected } = useContext(MenuContext);

    if (selected && !showSelected) {
      logger.error(
        "Can't show selected menu items without `showSelected` set; did you forget to provide MenuContext?"
      );
    }

    return (
      <div ref={ref} className="relative">
        <button
          onClick={(event) => {
            onClick?.(event);
            event.stopPropagation();
          }}
          className={classNames(
            showSelected ? "pl-8 pr-2" : "px-4",
            active && !disabled && !disabledTooltipMessage && "bg-zinc-100",
            danger && (active ? "text-red-700" : "text-red-600"),
            "w-full py-2 text-sm text-left text-zinc-700",
            (disabled || !!disabledTooltipMessage) &&
              "opacity-50 cursor-not-allowed"
          )}
          disabled={disabled || !!disabledTooltipMessage || loading}
        >
          <>
            {selected && (
              <div className="absolute left-0 top-0 bottom-0 w-8 flex items-center justify-center">
                <CheckIcon className="w-4 h-4 text-green-700" />
              </div>
            )}
            <div className="space-y-0.5">
              <div className={classNames(selected && "text-green-700")}>
                {children}
              </div>
              {subTitle && (
                <div className="text-zinc-500 text-xs">{subTitle}</div>
              )}
            </div>
          </>
        </button>
      </div>
    );
  }
);

const MenuAction = ({
  href,
  openInNewTab,
  children,
  ...props
}: MenuActionProps) => {
  const { disabledTooltipMessage } = props;

  return (
    <Menu.Item>
      {(menuItemProps) => {
        const { active } = menuItemProps;

        const childrenWithParentProps: React.ReactNode =
          typeof children === "function" ? children(menuItemProps) : children;

        return (
          <div>
            <Tippy
              placement="left"
              disabled={isNullOrUndefined(disabledTooltipMessage)}
              content={disabledTooltipMessage}
            >
              {href ? (
                <Link
                  href={href}
                  target={openInNewTab ? "_blank" : undefined}
                  onClick={(event) => event.stopPropagation()}
                >
                  <MenuActionContent active={active} {...props}>
                    {childrenWithParentProps}
                  </MenuActionContent>
                </Link>
              ) : (
                <MenuActionContent active={active} {...props}>
                  {childrenWithParentProps}
                </MenuActionContent>
              )}
            </Tippy>
          </div>
        );
      }}
    </Menu.Item>
  );
};

export default MenuAction;
