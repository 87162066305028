import {
  StatementImportedNotificationPayload,
  NotificationsQuery,
  StatementEntriesReconciledNotificationPayload,
  useMarkAllUnreadNotificationsAsReadMutation,
  useNotificationsQuery,
  useNumUnreadNotificationsQuery,
  CashReceivedBatchProcessedNotificationPayload,
  BillingMode,
  PayableEntryStatusesUpdateNotificationPayload,
  SuccessorPayableStatementCreatedNotificationPayload,
  NotificationsScope,
  StatementPostedNotificationPayload,
} from "../../src/generated/graphql";
import * as _ from "lodash";
import { NetworkStatus, gql } from "@apollo/client";
import { useRelayStyleFetchMore } from "../../hooks/useRelayStyleFetchMore";
import {
  isNotNullAndNotUndefined,
  isNullOrUndefined,
} from "../../src/formatting";
import { useInfiniteScrollTableRef } from "../../hooks/useInfiniteScrollTableRef";
import { useCallback, useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { client } from "../../pages/_app";
import ms from "ms";
import { StatementEntriesReconciledNotification } from "./StatementEntriesReconciledNotification";
import { StatementImportedNotification } from "./StatementImportedNotification";
import { assertDefined, assertUnreachableValue } from "../../src/utils";
import { CashReceivedBatchProcessedNotification } from "./CashReceivedBatchProcessedNotification";
import { PayableEntriesPayableEntryStatusUpdateNotification } from "./PayableEntriesPayableEntryStatusUpdateNotification";
import { SuccessorPayableStatementCreatedNotification } from "./SuccessorPayableStatementCreatedNotification";
import { StatementPostedNotification } from "./StatementPostedNotification";

const PAGE_LIMIT = 15;
const NOTIFICATIONS_MENU_WIDTH = 344;
const NOTIFICATIONS_MENU_HEIGHT = 480;

type Notification =
  NotificationsQuery["notifications"]["edges"][number]["node"];

export const NotificationsPanel = ({
  open,
  scope,
  onNotificationClick,
}: {
  open: boolean;
  scope: NotificationsScope;
  onNotificationClick: () => void;
}) => {
  const [markAllUnreadNotificationsAsRead] =
    useMarkAllUnreadNotificationsAsReadMutation();

  const { data: numUnreadNotificationsData } = useNumUnreadNotificationsQuery({
    pollInterval: ms("5 minutes"),
  });

  const numUnreadNotifications =
    numUnreadNotificationsData?.numUnreadNotifications ?? 0;

  const { data, previousData, refetch, fetchMore, networkStatus } =
    useNotificationsQuery({
      variables: {
        after: 0,
        pageSize: PAGE_LIMIT,
        scope,
      },
      notifyOnNetworkStatusChange: true,
    });

  const isLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;
  const isFetchingNextPage = networkStatus === NetworkStatus.fetchMore;
  const pageInfo = data?.notifications?.pageInfo;
  const hasNextPage = data?.notifications.pageInfo.hasNextPage;

  const fetchMoreNotifications = useRelayStyleFetchMore(
    isFetchingNextPage || isNullOrUndefined(data),
    fetchMore,
    pageInfo
  );

  const notificationList = useMemo(() => {
    const coalescedData = data || previousData;

    return coalescedData
      ? coalescedData.notifications.edges
          .filter(({ node: { payload } }) => {
            const payloadType = payload.__typename;
            assertDefined(payloadType);

            switch (payloadType) {
              case "StatementImportedNotificationPayload":
                return isNotNullAndNotUndefined(payload.statement);
              case "StatementPostedNotificationPayload":
                return (
                  isNotNullAndNotUndefined(payload.statement) &&
                  isNotNullAndNotUndefined(payload.outboundStatement)
                );
              case "StatementEntriesReconciledNotificationPayload":
                return (
                  isNotNullAndNotUndefined(payload.statement) &&
                  // Ensure reconciliation event still exists (entries haven't been unlinked)
                  payload.numReconciledEntries > 0
                );
              case "PayableEntryStatusesUpdateNotificationPayload":
                // Ensure the payable entry status is still the same as it was
                // upon the notification firing. We don't today
                // track an event_id when an entry's status changes, so we can't
                // check that the reconciliation is the same as it was upon notification
                // creation. This is best effort.
                return (
                  isNotNullAndNotUndefined(payload.statement) &&
                  payload.payableEntries.some(
                    ({ payableEntryStatus, billingMode }) =>
                      payableEntryStatus === payload.payableEntryStatus &&
                      billingMode === BillingMode.AGENCY_BILL
                  )
                );
              case "CashReceivedBatchProcessedNotificationPayload":
                return isNotNullAndNotUndefined(payload.cashReceivedBatch);
              case "SuccessorPayableStatementCreatedNotificationPayload":
                return (
                  isNotNullAndNotUndefined(payload.successorStatement) &&
                  isNotNullAndNotUndefined(
                    payload.successorStatement
                      ?.predecessorPayableCarrierStatement
                  ) &&
                  !_.isEmpty(payload.successorEntries)
                );
              case undefined:
              default:
                return assertUnreachableValue(payloadType);
            }
          })
          .map(({ node }) => node)
      : null;
  }, [data, previousData]);

  const notificationsContainerRef = useInfiniteScrollTableRef([]);
  const fetchNextPageOnBottomReached = useCallback(() => {
    if (notificationsContainerRef.current) {
      const { scrollHeight, scrollTop, clientHeight } =
        notificationsContainerRef.current;

      const scrollCloseToBottom =
        scrollHeight - scrollTop - clientHeight > 0 &&
        scrollHeight - scrollTop - clientHeight <
          0.8 * NOTIFICATIONS_MENU_HEIGHT;
      const numNotificationsToDisplayUnderLimit =
        notificationList && notificationList?.length < PAGE_LIMIT;

      const shouldFetchMoreNotifications =
        (scrollCloseToBottom || numNotificationsToDisplayUnderLimit) &&
        pageInfo?.hasNextPage;

      if (!isLoading && shouldFetchMoreNotifications) {
        fetchMoreNotifications();
      }
    }
  }, [
    notificationsContainerRef,
    isLoading,
    pageInfo?.hasNextPage,
    notificationList,
    fetchMoreNotifications,
  ]);

  useEffect(() => {
    // Fetch extra data if necessary on initial load - it's possible some notifications
    // are hidden if they have an out-of-date state, so we should fetch more notifications
    // if necessary
    if (!isLoading) {
      fetchNextPageOnBottomReached();
    }
  }, [fetchNextPageOnBottomReached, isLoading]);

  useEffect(() => {
    if (open) {
      // Refresh data upon opening the menu
      refetch();

      if (numUnreadNotifications > 0 && scope === NotificationsScope.USER) {
        markAllUnreadNotificationsAsRead();

        // Optimistically update Apollo cache
        client.writeQuery({
          query: gql`
            query numUnreadNotifications {
              numUnreadNotifications
            }
          `,
          data: {
            numUnreadNotifications: 0,
          },
        });
      }
    }
  }, [
    markAllUnreadNotificationsAsRead,
    numUnreadNotifications,
    open,
    refetch,
    scope,
  ]);

  return (
    <div
      ref={notificationsContainerRef}
      style={{
        width: NOTIFICATIONS_MENU_WIDTH,
        height: NOTIFICATIONS_MENU_HEIGHT,
      }}
      className="overflow-auto flex flex-col"
      onScroll={fetchNextPageOnBottomReached}
    >
      <div className="flex-grow">
        <div className="divide-y h-full">
          {notificationList
            ? notificationList.map((notification, ind) => (
                <div key={ind} onClick={onNotificationClick}>
                  <NotificationItem {...notification} />
                </div>
              ))
            : _.range(5).map((ind) => <NotificationSkeleton key={ind} />)}
          {hasNextPage && <NotificationSkeleton />}
          {notificationList && notificationList.length === 0 && (
            <div className="py-6 px-4 text-center text-xs text-zinc-500 flex flex-col flex-grow justify-center items-center space-y-2 h-full">
              <CheckBadgeIcon className="h-8 w-8 text-zinc-400" />
              <div>You're all caught up</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NotificationItem = ({ payload, createdAt }: Notification) => {
  const payloadType = payload.__typename;
  assertDefined(payloadType);

  switch (payloadType) {
    case "StatementImportedNotificationPayload":
      return (
        <StatementImportedNotification
          payload={payload as StatementImportedNotificationPayload}
          createdAt={createdAt}
        />
      );
    case "StatementPostedNotificationPayload":
      return (
        <StatementPostedNotification
          payload={payload as StatementPostedNotificationPayload}
          createdAt={createdAt}
        />
      );
    case "StatementEntriesReconciledNotificationPayload":
      return (
        <StatementEntriesReconciledNotification
          payload={payload as StatementEntriesReconciledNotificationPayload}
          createdAt={createdAt}
        />
      );
    case "PayableEntryStatusesUpdateNotificationPayload":
      return (
        <PayableEntriesPayableEntryStatusUpdateNotification
          payload={payload as PayableEntryStatusesUpdateNotificationPayload}
          createdAt={createdAt}
        />
      );
    case "SuccessorPayableStatementCreatedNotificationPayload":
      return (
        <SuccessorPayableStatementCreatedNotification
          payload={
            payload as SuccessorPayableStatementCreatedNotificationPayload
          }
          createdAt={createdAt}
        />
      );
    case "CashReceivedBatchProcessedNotificationPayload":
      return (
        <CashReceivedBatchProcessedNotification
          payload={payload as CashReceivedBatchProcessedNotificationPayload}
          createdAt={createdAt}
        />
      );
    default:
      return assertUnreachableValue(payloadType);
  }
};

const NotificationSkeleton = () => (
  <div className="py-3 px-4 flex space-x-3">
    <div className="shrink-0">
      <Skeleton circle width={24} height={24} />
    </div>
    <div className="space-y-1 flex-grow">
      <div className="w-full">
        <Skeleton width="100%" height={14} />
        <Skeleton width="60%" height={14} />
      </div>
      <Skeleton width="32%" height={10} />
    </div>
  </div>
);
