import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import React, { ReactElement } from "react";
import {
  classNames,
  getInitials,
  isNotNullAndNotUndefined,
} from "../src/formatting";
import {
  SelectButtonElement,
  SelectButtonElementProps,
} from "./SelectButtonElement";
import { AnchorPropsWithSelection } from "@headlessui/react/dist/internal/floating";
import AnimatedChevron from "./AnimatedChevron";

export function Avatar({ option }) {
  return (
    <span className="mr-2 inline-flex items-center justify-center h-6 w-6 rounded-full bg-zinc-500">
      <span className="text-xs font-medium leading-none text-white">
        {getInitials(option.value)}
      </span>
    </span>
  );
}

export type Option = {
  id: string;
  value: string;
  disabled?: boolean;
  fixed?: boolean;
  entityType?: string;
  optionElement?: (selected: boolean, active: boolean) => JSX.Element;
  optionClasses?: string;
  buttonElement?: () => JSX.Element;
};

type BaseProps<T extends Option> = {
  options: T[];
  mainClasses?: string;
  borderless?: boolean;
  showAvatars?: boolean;
  menuClasses?: string;
  selectedOption?: T | T[] | null;
  dimmed?: boolean;
  label?: string;
  multiple?: boolean;
  size?: SelectButtonElementProps["size"];
  fixedEmbed?: React.ReactNode;
  disabled?: boolean;
  nullOptionDisplay?: JSX.Element;
  /**
   * Positioning of the options relative to the button; if this is set, the
   * options will be rendered in a portal
   */
  anchor?: AnchorPropsWithSelection;
  /**
   * Whether to render menu options as portal; if this is set to `false`, the
   * `anchor` prop will be IGNORED
   */
  portal?: boolean;
  overrideButtonEl?: ReactElement<typeof ListboxButton>;
};

type PropsNoBlank<T extends Option> = BaseProps<T> & {
  allowSelectingBlank?: undefined | false;
  onChange: (selectedOption: T | T[]) => void;
};

type PropsWithBlank<T extends Option> = BaseProps<T> & {
  allowSelectingBlank: true;
  onChange: (selectedOption: T | T[] | null) => void;
};

type Props<T extends Option> = PropsNoBlank<T> | PropsWithBlank<T>;

export default function Select<T extends Option>({
  options,
  selectedOption,
  onChange,
  borderless = false,
  mainClasses,
  allowSelectingBlank = false,
  showAvatars,
  menuClasses,
  label,
  multiple,
  size,
  fixedEmbed,
  dimmed,
  disabled = false,
  overrideButtonEl,
  anchor = "bottom start",
  portal = true,
  nullOptionDisplay = (
    <span className="text-zinc-300 whitespace-pre-line">—</span>
  ),
}: Props<T>) {
  // Insert blank option as first option if necessary
  const optionsToRender = (
    allowSelectingBlank ? [null, ...options] : options
  ).filter((option) => !option?.fixed);
  const fixedOptionsToRender = options.filter((option) => !!option?.fixed);

  const renderOption = (option: Option | null) => (
    <ListboxOption
      key={option ? option.id : "blank"}
      disabled={option?.disabled}
      className={({ active }) =>
        classNames(
          active ? "bg-zinc-100" : "",
          option?.disabled ? "cursor-not-allowed opacity-50" : "cursor-default",
          "select-none relative py-2 pl-9 pr-4 text-zinc-900 w-full",
          option?.optionClasses
        )
      }
      value={option?.value ? option : null}
    >
      {({ selected, active }) => {
        return typeof option?.optionElement === "function" ? (
          option.optionElement(selected, active)
        ) : (
          <div className="flex items-center">
            {isNotNullAndNotUndefined(showAvatars) && (
              <Avatar option={option} />
            )}
            <span
              className={classNames(
                "font-normal block truncate",
                size === "small" && "text-sm",
                selected ? "text-green-700" : ""
              )}
            >
              {option?.value ? option.value : "—"}
            </span>
            {selected ? (
              <span className="text-green-700 absolute inset-y-0 left-0 flex items-center pl-2">
                <div className="w-5 h-5 flex items-center justify-center">
                  <CheckIcon
                    className={size === "small" ? "w-3 h-3" : "w-4 h-4"}
                  />
                </div>
              </span>
            ) : null}
          </div>
        );
      }}
    </ListboxOption>
  );

  return (
    <Listbox
      as="div"
      by="id"
      value={selectedOption || null}
      onChange={onChange}
      multiple={multiple}
      className="w-full"
      disabled={disabled}
    >
      {({ open }) => (
        <div className="relative grow">
          {label && (
            <Label className="text-sm leading-5 font-medium text-zinc-700">
              {label}
            </Label>
          )}
          {overrideButtonEl ? (
            overrideButtonEl
          ) : (
            <ListboxButton
              className={classNames(
                isNotNullAndNotUndefined(mainClasses) ? mainClasses : "",
                "flex flex-row space-x-1 text-left cursor-default items-center relative group",
                size === "small"
                  ? "min-h-[24px] py-1 pl-1 pr-6"
                  : "min-h-[36px] pl-3 py-2 pr-8",
                borderless
                  ? "text-base text-zinc-600 font-medium hover:text-zinc-900"
                  : "text-sm justify-between rounded-md border border-zinc-300 focus:outline-none shadow-sm hover:border-zinc-400 transition-[border]",
                borderless && "cursor-pointer"
              )}
            >
              {isNotNullAndNotUndefined(showAvatars) && (
                <Avatar option={selectedOption} />
              )}
              <span
                className={classNames(
                  "truncate w-full",
                  !selectedOption && "text-zinc-300",
                  borderless && "cursor-pointer"
                )}
              >
                {Array.isArray(selectedOption) ? (
                  selectedOption.map((option) => option.value).join(", ") || "—"
                ) : selectedOption ? (
                  typeof selectedOption?.buttonElement === "function" ? (
                    selectedOption.buttonElement()
                  ) : (
                    <SelectButtonElement
                      size={size}
                      variant={dimmed ? "dimmed" : "normal"}
                      line1Text={selectedOption.value}
                    />
                  )
                ) : (
                  nullOptionDisplay
                )}
              </span>
              {/* Because of the override props built into select, we must use
              this absolute positioning to get the chevron in the right position */}
              <div className="absolute right-2 rounded-r-md pl-2">
                <AnimatedChevron open={open} />
              </div>
            </ListboxButton>
          )}
          <ListboxOptions
            transition
            // By default, if the `anchor` is provided, then Headless UI will set`portal` to `true`;
            // therefore, in order to disable `portal` behavior, we must not provide an `anchor` prop
            anchor={portal && anchor}
            portal={portal}
            className={classNames(
              "w-60 h-min z-10 [--anchor-gap:4px] bg-white shadow-lg border border-zinc-200 rounded-md text-base focus:outline-none sm:text-sm origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0",
              !portal && "absolute mt-1",
              menuClasses
            )}
          >
            <div
              className={classNames(
                "overflow-auto py-1 max-h-48",
                !fixedEmbed && fixedOptionsToRender.length === 0
                  ? "rounded-md"
                  : ""
              )}
            >
              {optionsToRender.map(renderOption)}
            </div>
            {fixedEmbed && <div className="py-1">{fixedEmbed}</div>}
            {fixedOptionsToRender.length !== 0 && (
              <div className="py-1">
                {fixedOptionsToRender.map(renderOption)}
              </div>
            )}
          </ListboxOptions>
        </div>
      )}
    </Listbox>
  );
}
