import { Float, FloatProps } from "@headlessui-float/react";
import {
  Menu as HeadlessMenu,
  MenuButton as HeadlessMenuButton,
  MenuItems as HeadlessMenuItems,
  MenuItem as HeadlessMenuItem,
  MenuItemsProps,
} from "@headlessui/react";
import { createContext, ReactElement, ReactNode, useContext } from "react";
import MenuAction from "./actions/MenuAction";
import DepositLinkMenuAction from "./actions/DepositLinkMenuAction";
import { flip } from "@floating-ui/react";
import { classNames } from "../../src/formatting";

export const MenuContext = createContext<{
  showSelected?: boolean;
}>({});

export type MenuProps = {
  width?: number | string;
  showSelected?: boolean;
  button: ReactElement<typeof HeadlessMenu.Button>;
  onClose?: () => void;
  onOpen?: () => void;
  children:
    | ReactElement<typeof MenuGroup>
    | Array<ReactElement<typeof MenuGroup> | null>
    | (({ open, close }: { open: boolean; close: () => void }) => ReactNode);
  menuClassNames?: string;
  floatProps?: Partial<FloatProps>;
  menuItemsProps?: Partial<MenuItemsProps>;
};

/**
 * This Menu component is a wrapper around the Headless UI Menu component. It
 * is expected that the children of this component will be Menu.Group components.
 *
 * Within each Menu.Group, you can have Menu.Item components, which are the original
 * Headless UI Menu.Item components, and thus can be used the same way.
 *
 * For convenience, this also provides a Menu.Action component, which is a wrapper
 * around the original Menu.Item component, but with some additional styling and
 * functionality (like a loading state and a disabled tooltip).
 */
export const Menu = ({
  width,
  showSelected,
  button,
  onClose,
  onOpen,
  children,
  menuClassNames,
  floatProps = {},
  menuItemsProps = {},
}: MenuProps) => (
  <MenuContext.Provider value={{ showSelected }}>
    <HeadlessMenu as="div" className={menuClassNames}>
      {({ open, close }) => (
        <Float
          portal
          onHide={() => {
            if (!open) {
              onClose?.();
            }
          }}
          onShow={() => {
            if (open) {
              onOpen?.();
            }
          }}
          middleware={[
            flip({
              mainAxis: true,
              crossAxis: true,
            }),
          ]}
          offset={4}
          className="focus:outline-none"
          placement="bottom-end"
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          {...floatProps}
        >
          {button}
          <HeadlessMenuItems
            style={{ width }}
            className="z-[1] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y"
            {...menuItemsProps}
          >
            {typeof children === "function"
              ? children({ open, close })
              : children}
          </HeadlessMenuItems>
        </Float>
      )}
    </HeadlessMenu>
  </MenuContext.Provider>
);

const MenuGroup = <S extends typeof HeadlessMenuItem | typeof MenuSubGroup>({
  children,
}: {
  children: ReactElement<S> | Array<ReactElement<S> | null> | null;
}) => <div className="py-1">{children}</div>;

const MenuSubGroup = <S extends typeof HeadlessMenuItem>({
  title,
  children,
}: {
  title: string;
  children: ReactElement<S> | Array<ReactElement<S> | null> | null;
}) => {
  const { showSelected: showActive } = useContext(MenuContext);

  return (
    <>
      <div
        className={classNames(
          "pt-2 pb-1 px-4 text-xs leading-4 font-medium text-zinc-400 cursor-default",
          showActive ? "pl-8 pr-2" : "px-4"
        )}
      >
        {title}
      </div>
      {children}
    </>
  );
};

/** Headless UI attributes */
Menu.Button = HeadlessMenuButton;
Menu.Item = HeadlessMenuItem;

/** Custom attributes */
Menu.Group = MenuGroup;
Menu.SubGroup = MenuSubGroup;
Menu.Action = MenuAction;
Menu.DepositLinkAction = DepositLinkMenuAction;
