import { captureException } from "@sentry/browser";
import { useCallback } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps,
} from "react-error-boundary";
import { logPageCrash } from "../src/analytics/pageCrashAnalytics";

type Props = ErrorBoundaryProps & {
  children: React.ReactNode;
};

export default function ErrorBoundary(props: Props) {
  const { onError, ...restProps } = props;
  const wrappedOnError = useCallback<NonNullable<Props["onError"]>>(
    (error, info) => {
      // Report the error to Sentry
      const { componentStack } = info;
      const errorBoundaryError = new Error(error.message);
      errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
      errorBoundaryError.stack = componentStack;
      error.cause = errorBoundaryError;
      captureException(error, { contexts: { react: { componentStack } } });
      logPageCrash(errorBoundaryError.name, error.message);
      onError?.(error, info);
    },
    [onError]
  );

  return (
    <ReactErrorBoundary onError={wrappedOnError} {...restProps}>
      {props.children}
    </ReactErrorBoundary>
  );
}
