import { getCookie } from "cookies-next";
import LogRocket from "logrocket";
import logger from "./logger";

declare global {
  interface Window {
    LogRocket: typeof LogRocket | undefined;
  }
}

// LogRocket might not initialize due to things like ad-blockers in the
// browser preventing the setup script from loading.
const isLogRocketEnabled =
  process.env.NEXT_PUBLIC_LOGROCKET_DISABLED !== "true" &&
  !getCookie("LOGROCKET_DISABLED");

if (isLogRocketEnabled && typeof window !== "undefined") {
  LogRocket.init("nhdlwn/comulate", {
    shouldParseXHRBlob: true,
    network: {
      requestSanitizer: (request) => {
        if ("authorization" in request.headers) {
          request.headers["authorization"] = "SANITIZED";
        }
        return request;
      },
    },
  });

  logger.info("[LogRocket] LogRocket client initialized");

  window.LogRocket = LogRocket;
}
