import { useState } from "react";
import { Button } from "../../Button";
import InlineLoader from "../../InlineLoader";
import ModalWithContent from "../../ModalWithContent";

const OpenNextPeriodModal = ({
  open,
  setOpen,
  periodToOpen,
  onConfirm,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  periodToOpen: string;
  onConfirm: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <ModalWithContent
      title="Open Next Period"
      showCloseButton
      body={
        <div className="text-base leading-6 font-normal text-zinc-700 w-[600px]">
          <div className="mb-7">
            Are you sure you'd like to open the <strong>{periodToOpen}</strong>{" "}
            period?
          </div>
          <div className="mb-7">
            Periods can only be deleted by Comulate's support team.
          </div>
        </div>
      }
      footer={
        <div className="flex justify-end space-x-2">
          <Button colorScheme="white" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              setOpen(false);
              await onConfirm();
              setLoading(false);
            }}
          >
            {loading && <InlineLoader className="mr-2" />}
            Open period
          </Button>
        </div>
      }
      open={open}
      setOpen={setOpen}
    />
  );
};

export default OpenNextPeriodModal;
