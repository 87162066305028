import * as React from "react";

const LockIcon16 = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9125 7.02907V4.60197C10.9125 2.99343 9.60849 1.68945 7.99995 1.68945C6.39141 1.68945 5.08743 2.99343 5.08743 4.60197V7.02907M4.60201 14.3104H11.3979C12.2022 14.3104 12.8542 13.6584 12.8542 12.8541V8.48533C12.8542 7.68106 12.2022 7.02907 11.3979 7.02907H4.60201C3.79774 7.02907 3.14575 7.68106 3.14575 8.48533V12.8541C3.14575 13.6584 3.79774 14.3104 4.60201 14.3104Z"
      stroke="#A1A1AA"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LockIcon16;
