"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMedianConfidenceOfPrimaryTable = exports.splitBlockByText = exports.extractBlocksByKeyValues = exports.getChildRelationshipIds = void 0;
const mathUtils_1 = require("./mathUtils");
const utils_1 = require("../common/utils");
const documentIdentifierHeaders_1 = require("../import/documentIdentifierHeaders");
const _ = __importStar(require("lodash"));
const getChildRelationshipIds = (block) => {
    const childRelationship = Array.isArray(block.Relationships) && block.Relationships.length > 0
        ? block.Relationships.find((r) => r.Type === "CHILD")
        : undefined;
    if (childRelationship === undefined)
        return undefined;
    return childRelationship.Ids;
};
exports.getChildRelationshipIds = getChildRelationshipIds;
/**
 * Splits a consecutive list of blocks by specified keywords. Returns a map
 * from each specified field to the list of blocks that follow it.
 *
 * @param blocks
 * @param config
 * @returns
 */
const extractBlocksByKeyValues = (blocks, config) => {
    const allResults = [];
    for (let i = 0; i < blocks.length; i++) {
        for (let fieldInd = 0; fieldInd < config.length; fieldInd++) {
            const { fieldName, startWord, endWord } = config[fieldInd];
            const numStartWords = startWord.split(" ").length;
            let j = i;
            // Find the first word blocks that contain the start word -
            // will denote the start of the field
            while (j < blocks.length - numStartWords) {
                const textInRange = sliceText(blocks, j, j + numStartWords);
                if (!textInRange.includes(startWord)) {
                    j++;
                }
                else {
                    break;
                }
            }
            // Store index to indicate start of field
            const startJ = j;
            // Find blocks until end word, or until end of line if none specified
            while (j < blocks.length &&
                (!endWord || !blocks[j].text.includes(endWord))) {
                j++;
            }
            allResults.push([fieldName, blocks.slice(startJ + numStartWords, j)]);
            i = fieldInd === config.length - 1 ? blocks.length : j;
        }
    }
    return Object.fromEntries(allResults);
};
exports.extractBlocksByKeyValues = extractBlocksByKeyValues;
const sliceText = (blocks, startInd, endInd) => blocks
    .slice(startInd, endInd)
    .map((b) => b.text)
    .join(" ");
const splitBlockByText = (allBlocks, block, textA, textB) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const idA = block.Id;
    const idB = block.Id + "_2";
    const numCharsA = textA.length;
    const numCharsB = textB.length;
    const blockRatio = numCharsA / (numCharsA + numCharsB);
    const geometryA = Object.assign(Object.assign({}, block.Geometry), { BoundingBox: Object.assign(Object.assign({}, (_a = block.Geometry) === null || _a === void 0 ? void 0 : _a.BoundingBox), { Width: ((_c = (_b = block.Geometry) === null || _b === void 0 ? void 0 : _b.BoundingBox) === null || _c === void 0 ? void 0 : _c.Width) * blockRatio }) });
    const geometryB = Object.assign(Object.assign({}, block.Geometry), { BoundingBox: Object.assign(Object.assign({}, (_d = block.Geometry) === null || _d === void 0 ? void 0 : _d.BoundingBox), { Width: ((_f = (_e = block.Geometry) === null || _e === void 0 ? void 0 : _e.BoundingBox) === null || _f === void 0 ? void 0 : _f.Width) * (1 - blockRatio), Left: ((_h = (_g = block.Geometry) === null || _g === void 0 ? void 0 : _g.BoundingBox) === null || _h === void 0 ? void 0 : _h.Left) +
                ((_k = (_j = block.Geometry) === null || _j === void 0 ? void 0 : _j.BoundingBox) === null || _k === void 0 ? void 0 : _k.Width) * blockRatio }) });
    const parentBlocks = allBlocks.filter((otherBlock) => { var _a; return (_a = (0, exports.getChildRelationshipIds)(otherBlock)) === null || _a === void 0 ? void 0 : _a.includes(block.Id); });
    parentBlocks.forEach((other) => {
        if (other.Relationships) {
            other.Relationships = other.Relationships.map((relationship) => (Object.assign(Object.assign({}, relationship), { Ids: [
                    ...(relationship.Ids || []).filter((id) => id !== block.Id),
                    idA,
                    idB,
                ] })));
        }
    });
    return [
        Object.assign(Object.assign({}, block), { Id: idA, Text: textA, Geometry: geometryA }),
        Object.assign(Object.assign({}, block), { Id: idB, Text: textB, Geometry: geometryB }),
    ];
};
exports.splitBlockByText = splitBlockByText;
/**
 * 4/21/23 (AA) - Due to our current azure -> textract conversion process, this method will always return 0
 * for azure derived blocks. This is because:
 * 1. We don't set the Cell.Text field
 * 2. We don't set the Cell.Confidence field
 */
const getMedianConfidenceOfPrimaryTable = (blocks, documentIdentifierHeaders) => {
    var _a;
    const blocksById = _.keyBy(blocks, "Id");
    const tableBlocks = blocks.filter((block) => block.BlockType === "TABLE");
    const candidateTables = tableBlocks.map((tableBlock) => {
        const childIds = (0, exports.getChildRelationshipIds)(tableBlock);
        if (childIds === undefined || !tableBlock.Id)
            return { table: tableBlock, childCells: [], numHeadersFound: 0 };
        const childCells = childIds
            .map((id) => blocksById[id])
            .filter(({ BlockType }) => BlockType === "CELL");
        const columnHeaderBlocks = childCells.filter(({ EntityTypes }) => EntityTypes === null || EntityTypes === void 0 ? void 0 : EntityTypes.includes("COLUMN_HEADER"));
        const numHeadersFound = columnHeaderBlocks
            .map(exports.getChildRelationshipIds)
            .flat()
            .filter(utils_1.isNotNullAndNotUndefined)
            .map((id) => blocksById[id])
            .map((b) => (0, documentIdentifierHeaders_1.cleanRawTextForHeader)(b.Text))
            .filter(utils_1.isNotNullAndNotUndefined)
            .filter((b) => documentIdentifierHeaders.includes(b)).length;
        return { table: tableBlock, childCells, numHeadersFound };
    });
    const mainTable = _.maxBy(candidateTables, (tableWithScore) => tableWithScore.numHeadersFound);
    const cellConfidences = (_a = mainTable === null || mainTable === void 0 ? void 0 : mainTable.childCells.map((cell) => cell.Confidence).filter(utils_1.isNotNullAndNotUndefined)) !== null && _a !== void 0 ? _a : [];
    // Find median confidence
    return (0, mathUtils_1.median)(cellConfidences);
};
exports.getMedianConfidenceOfPrimaryTable = getMedianConfidenceOfPrimaryTable;
