import { Button, CustomButtonProps } from "../Button";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { Menu, MenuProps } from "./Menu";
import { classNames } from "../../src/formatting";

export const EllipsisMenu = ({
  variant = "flat",
  width,
  onOpen,
  onClose,
  iconClassName,
  floatProps,
  children,
  ...buttonProps
}: {
  variant?: "block" | "flat";
  iconClassName?: string;
} & Omit<MenuProps, "button"> &
  CustomButtonProps) => (
  <Menu
    width={width}
    onOpen={onOpen}
    onClose={onClose}
    floatProps={floatProps}
    button={
      <Menu.Button
        as={Button}
        colorScheme={variant === "flat" ? "inline" : "white"}
        size="xs"
        className={variant === "flat" ? "!p-1" : "!p-2"}
        onClick={(event) => event.stopPropagation()}
        {...buttonProps}
      >
        <div className="flex items-center justify-center">
          <EllipsisHorizontalIcon
            className={classNames(
              variant === "flat" ? "w-6 h-6" : "w-4 h-4",
              "text-zinc-500",
              iconClassName
            )}
          />
        </div>
      </Menu.Button>
    }
  >
    {({ open, close }) =>
      typeof children === "function" ? children({ open, close }) : children
    }
  </Menu>
);
