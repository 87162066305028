"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fn = exports.identifier = exports.text = exports.p = exports.doc = exports.transformStrToTipTapJson = exports.getTiptapIdentifierValues = exports.serializeTiptapJson = exports.generateTiptapFormulas = void 0;
const utils_1 = require("./utils");
const _ = __importStar(require("lodash"));
const hyperformula_1 = require("./hyperformula");
const hyperformula_2 = require("hyperformula");
const graphql_1 = require("../generated/graphql");
const utils_2 = require("../common/utils");
hyperformula_2.HyperFormula.registerFunctionPlugin(hyperformula_1.HyperFormulaCustom, hyperformula_1.HyperFormulaCustomTranslations);
const HYPERFORMULA_FUNCTIONS = hyperformula_2.HyperFormula.getRegisteredFunctionNames("enGB");
const FUNCTION_COLORS = [
    graphql_1.TagColor.AMBER,
    graphql_1.TagColor.BLUE,
    graphql_1.TagColor.CYAN,
    graphql_1.TagColor.FUCHSIA,
    graphql_1.TagColor.INDIGO,
    graphql_1.TagColor.LIME,
    graphql_1.TagColor.ORANGE,
    graphql_1.TagColor.PINK,
    graphql_1.TagColor.RED,
    graphql_1.TagColor.SKY,
    graphql_1.TagColor.TEAL,
    graphql_1.TagColor.VIOLET,
    graphql_1.TagColor.YELLOW,
    graphql_1.TagColor.ZINC,
];
/**
 * Generate an array of formulas based on the provided nodes, replacing
 * placeholders (%d) with row indices.
 */
const generateTiptapFormulas = ({ nodes, columnNames, numRows, removingHangingNewline = true, }) => {
    var _a;
    const splitFormulas = transformFormulaContent(columnNames, nodes);
    if (removingHangingNewline && ((_a = splitFormulas.at(-1)) === null || _a === void 0 ? void 0 : _a.text) === "\n") {
        splitFormulas.pop();
    }
    return _.range(numRows).map((ind) => splitFormulas
        .map(({ text }) => text)
        .filter(utils_2.isNotNullAndNotUndefined)
        .map((cell) => cell === null || cell === void 0 ? void 0 : cell.replaceAll("%d", (ind + 1).toString()))
        .filter((cell) => cell === null || cell === void 0 ? void 0 : cell.length));
};
exports.generateTiptapFormulas = generateTiptapFormulas;
const transformFormulaContent = (columnNames, document) => {
    if (!(document.type === "doc" && document.content))
        throw new Error('Expected node to be of type "doc" with content');
    const nodes = document.content.flatMap((childNode) => {
        if (childNode.type === "paragraph") {
            return [
                ...(childNode.content || []),
                { type: "text", text: "\n" },
            ];
        }
        return [childNode];
    });
    let i = 0;
    let lastFnInd = 0;
    let formulaCount = 0;
    const updatedNodes = [];
    while (i < nodes.length) {
        if (nodes[i].type === "mention") {
            const attrs = nodes[i].attrs;
            if (lastFnInd < i) {
                updatedNodes.push(...nodes.slice(lastFnInd, i));
            }
            if (attrs.variant === "function") {
                let escaped = false;
                let started = false;
                let k = i;
                let parenCount = 0;
                while (k < nodes.length && (parenCount > 0 || !started)) {
                    const str = nodes[k].text || "";
                    for (let charInd = 0; charInd < str.length; charInd++) {
                        const c = str[charInd];
                        if (c === '"')
                            escaped = !escaped;
                        if (!escaped && c === "(")
                            parenCount++;
                        if (!escaped && c === ")")
                            parenCount--;
                        if (!escaped && c === "(")
                            started = true;
                        if (started && parenCount === 0) {
                            const a = str.slice(0, charInd + 1);
                            const b = str.slice(charInd + 1);
                            // Split up text so that characters associated with formula
                            // are in separate node for serialization
                            nodes[k].text = a;
                            nodes.splice(k + 1, 0, { type: "text", text: b });
                            break;
                        }
                    }
                    k++;
                }
                const formula = (0, exports.serializeTiptapJson)(columnNames, {
                    type: "paragraph",
                    content: nodes.slice(i, k),
                }).trim();
                updatedNodes.push({
                    type: "formula",
                    text: `=${formula}`,
                    ind: formulaCount,
                });
                i = k;
                lastFnInd = k;
                formulaCount++;
            }
            if (attrs.variant === "identifier") {
                const formula = (0, exports.serializeTiptapJson)(columnNames, {
                    type: "paragraph",
                    content: nodes.slice(i, i + 1),
                }).trim();
                updatedNodes.push({
                    type: "formula",
                    text: `=${formula}`,
                    ind: formulaCount,
                });
                lastFnInd = i + 1;
                formulaCount++;
            }
        }
        i++;
    }
    if (lastFnInd < nodes.length) {
        updatedNodes.push(...nodes.slice(lastFnInd));
    }
    return updatedNodes;
};
const serializeTiptapJson = (columnNames, node) => {
    const content = transformTiptapJsonToStr(columnNames, node);
    // Replace non-breaking spaces with regular spaces, as they are not
    // supported by HyperFormula
    return content.replace(/\u00A0/g, " ");
};
exports.serializeTiptapJson = serializeTiptapJson;
const transformTiptapJsonToStr = (columnNames, node) => {
    var _a;
    const childContent = ((_a = node.content) === null || _a === void 0 ? void 0 : _a.map((childNode) => (0, exports.serializeTiptapJson)(columnNames, childNode)).join("")) || "";
    if (node.type === "mention") {
        const attrs = node.attrs;
        if (attrs.variant === "identifier") {
            const ind = columnNames.indexOf(attrs.id);
            return `${(0, utils_1.encodeNumberAsCell)(ind)}%d`;
        }
        if (attrs.variant === "function") {
            return attrs.id;
        }
    }
    if (node.type === "formula") {
        return node.text || "";
    }
    if (node.type === "text") {
        return node.text || "";
    }
    if (node.type === "paragraph") {
        return childContent + "\n";
    }
    if (node.type === "doc") {
        return childContent;
    }
    return "";
};
const getTiptapIdentifierValues = (node) => {
    var _a;
    const childContent = ((_a = node.content) === null || _a === void 0 ? void 0 : _a.flatMap(exports.getTiptapIdentifierValues)) || [];
    if (node.type === "mention") {
        const attrs = node.attrs;
        if (attrs.variant === "identifier") {
            return [attrs.id, ...childContent];
        }
    }
    return childContent;
};
exports.getTiptapIdentifierValues = getTiptapIdentifierValues;
const transformStrToTipTapJson = (columnNames, str) => {
    let i = 0;
    let lastFnInd = 0;
    let colorCount = 0;
    const out = [];
    while (i < str.length) {
        const fn = HYPERFORMULA_FUNCTIONS.find((fn) => str.startsWith(fn, i) && str[i + fn.length] === "(");
        if (fn) {
            if (lastFnInd < i) {
                const text = str.slice(lastFnInd, i);
                if (text) {
                    out.push({
                        type: "text",
                        text,
                    });
                }
            }
            out.push({
                type: "mention",
                attrs: {
                    id: fn,
                    label: fn,
                    color: FUNCTION_COLORS[colorCount % (FUNCTION_COLORS.length - 1)],
                    variant: "function",
                },
            });
            i += fn.length - 1;
            lastFnInd = i + 1;
            colorCount++;
        }
        const identifier = columnNames.find((col) => str.startsWith(col, i) &&
            str[i - 1] === `"` &&
            str[i + col.length] === `"`);
        if (identifier) {
            if (lastFnInd < i) {
                const text = str.slice(lastFnInd, i - 1);
                if (text) {
                    out.push({
                        type: "text",
                        text,
                    });
                }
            }
            out.push({
                type: "mention",
                attrs: {
                    id: identifier,
                    label: identifier,
                    color: FUNCTION_COLORS[colorCount % (FUNCTION_COLORS.length - 1)],
                    variant: "identifier",
                },
            });
            i += identifier.length;
            lastFnInd = i + 1;
            colorCount++;
        }
        i++;
    }
    if (lastFnInd < str.length) {
        out.push({
            type: "text",
            text: str.slice(lastFnInd),
        });
    }
    return {
        type: "doc",
        content: [
            {
                type: "paragraph",
                content: out,
            },
        ],
    };
};
exports.transformStrToTipTapJson = transformStrToTipTapJson;
/** UTILITIES FOR GENERATING TIPTAP JSON */
const doc = (content) => ({
    type: "doc",
    content,
});
exports.doc = doc;
const p = (content) => ({
    type: "paragraph",
    content,
});
exports.p = p;
const text = (text) => ({
    type: "text",
    text,
});
exports.text = text;
const identifier = (identifier) => ({
    type: "mention",
    attrs: {
        id: identifier,
        label: identifier,
        color: null,
        variant: "identifier",
    },
});
exports.identifier = identifier;
const fn = (fn) => ({
    type: "mention",
    attrs: {
        id: fn,
        label: fn,
        color: null,
        variant: "function",
    },
});
exports.fn = fn;
