import logger from "../logger";
import { ButtonLoggingEvent } from "./buttonAnalytics";
import { CashReceivedBatchImportLoggingEvent } from "./cashReceivedBatches";
import { InvoicingLoggingEvent } from "./invoicing";
import { LedgerLoggingEvent } from "./ledgerAnalytics";
import { PageCrashLoggingEvent } from "./pageCrashAnalytics";
import { PayablesLoggingEvent } from "./payableAnalytics";
import { StatementImportLoggingEvent } from "./statementImport";
import { TriageLoggingEvent } from "./triageAnalytics";
import { FilterLoggingEvent } from "./filterAnalytics";

export enum Location {
  STATEMENTS_PAGE = "STATEMENTS_PAGE",
  STATEMENT_IMPORT_TAKEOVER = "STATEMENT_IMPORT_TAKEOVER",
  STATEMENT_DETAIL = "STATEMENT_DETAIL",
  ACCOUNTS_RECEIVABLE_PAGE = "ACCOUNTS_RECEIVABLE_PAGE",
  INVOICING_PAGE = "INVOICING_PAGE",
  PAYABLES_PAGE = "PAYABLES_PAGE",
  LEDGER = "LEDGER",
  OWL = "OWL",
}

type LoggingEvent =
  | PageCrashLoggingEvent
  | StatementImportLoggingEvent
  | TriageLoggingEvent
  | ButtonLoggingEvent
  | CashReceivedBatchImportLoggingEvent
  | InvoicingLoggingEvent
  | PayablesLoggingEvent
  | LedgerLoggingEvent
  | FilterLoggingEvent;

export const logEvent = (event: LoggingEvent, options: object) => {
  // Guard against adblockers.
  if (typeof global.analytics?.track === "function") {
    const modifiedOptions = {
      ...options,
      ...(global.analytics.currentPeriod
        ? { currentPeriod: global.analytics.currentPeriod }
        : {}),
    };

    global.analytics.track(event, modifiedOptions);
  } else {
    logger.info(
      "Skipping segment API `identify` call, because it is not initialized. One cause of this might be a user's ad-blocker."
    );
  }
};
