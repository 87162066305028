import * as React from "react";

function ComulateBrandMark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={21}
      fill="none"
      viewBox="0 0 32 21"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19.148.816 8.521 20.933h4.396L23.544.816zM18.868 13.194a4.6 4.6 0 0 0-.019.768h-.003c.076 1.294.731 2.501 1.796 3.31l4.819 3.665 1.663-3.147-4.392-3.34c-.24-.182-.394-.444-.408-.697v-.109c.022-.58.52-1.025 1.088-1.025h6.445l1.86-3.526h-8.232c-2.335 0-4.355 1.749-4.617 4.1M13.201 8.556q.042-.396.02-.769v.003c-.077-1.293-.732-2.5-1.796-3.31L6.605.817 4.942 3.959 9.334 7.3c.24.183.394.444.408.698v.108c-.022.581-.52 1.025-1.088 1.025H2.21L.349 12.656h8.237c2.334-.001 4.354-1.75 4.615-4.1"
      />
    </svg>
  );
}

export default ComulateBrandMark;
