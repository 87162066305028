"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.greaterThanWithTolerance = exports.lessThanWithTolerance = exports.equalWithTolerance = exports.valuesEqual = exports.DEFAULT_FUZZY_EQUALITY_TOLERANCE = exports.DEFAULT_STRICT_EQUALITY_TOLERANCE = exports.yieldEventLoop = exports.levenshteinDistance = exports.isNullOrUndefined = exports.isNotNullAndNotUndefined = exports.hashString = exports.findOrFail = exports.assertUnreachableValue = void 0;
/** Used to assert that the value in a given codepath is unreachable so Typescript
 * can better infer types.
 */
const assertUnreachableValue = (value) => {
    throw new Error(`Reached an unreachable codepath with value ${value}`);
};
exports.assertUnreachableValue = assertUnreachableValue;
const findOrFail = (list, predicate) => {
    const result = list.find(predicate);
    if (result === undefined) {
        throw new Error("Could not find element");
    }
    return result;
};
exports.findOrFail = findOrFail;
/** Simple hash function for a string */
const hashString = (str) => {
    return [...str].reduce((hash, char) => {
        const charNum = char.charCodeAt(0);
        const bitShifted = (hash << 5) - hash + charNum;
        return bitShifted & bitShifted;
    }, 0);
};
exports.hashString = hashString;
const isNotNullAndNotUndefined = (arg) => {
    return arg !== null && arg !== undefined;
};
exports.isNotNullAndNotUndefined = isNotNullAndNotUndefined;
const isNullOrUndefined = (arg) => {
    return arg === null || arg === undefined;
};
exports.isNullOrUndefined = isNullOrUndefined;
const levenshteinDistance = (str1, str2) => {
    const track = Array(str2.length + 1)
        .fill(null)
        .map(() => Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
        track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
        track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
        for (let i = 1; i <= str1.length; i += 1) {
            const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
            track[j][i] = Math.min(track[j][i - 1] + 1, // deletion
            track[j - 1][i] + 1, // insertion
            track[j - 1][i - 1] + indicator // substitution
            );
        }
    }
    return track[str2.length][str1.length];
};
exports.levenshteinDistance = levenshteinDistance;
/** Utility to unblock event-loop */
const yieldEventLoop = () => __awaiter(void 0, void 0, void 0, function* () { return yield new Promise((r) => setTimeout(r, 0)); });
exports.yieldEventLoop = yieldEventLoop;
// Used in cases where we want to compare floating point numbers AND care about cent level differences
exports.DEFAULT_STRICT_EQUALITY_TOLERANCE = 0.001;
// Used in cases where we want to compare floating point numbers AND don't care about 1 cent differences
exports.DEFAULT_FUZZY_EQUALITY_TOLERANCE = 0.015;
/**
 * Checks that value A and value B are equal, taking into account floating point errors
 * @param valueA the first value to compare
 * @param valueB the second value to compare
 * @returns if valueA and valueB are equal when taking floating point errors into account
 */
const valuesEqual = (valueA, valueB) => (0, exports.equalWithTolerance)(valueA, valueB, exports.DEFAULT_STRICT_EQUALITY_TOLERANCE);
exports.valuesEqual = valuesEqual;
/**
 * Checks that value A and value B are within the provided tolerance of each other
 * @param valueA the first value to compare
 * @param valueB the second value to compare
 * @param tolerance the tolerance to use when comparing the values
 * @returns if valueA and valueB are within the provided tolerance of each other
 */
const equalWithTolerance = (valueA, valueB, tolerance = exports.DEFAULT_FUZZY_EQUALITY_TOLERANCE) => {
    if ((0, exports.isNullOrUndefined)(valueA) || (0, exports.isNullOrUndefined)(valueB))
        return false;
    return Math.abs(valueA - valueB) <= tolerance;
};
exports.equalWithTolerance = equalWithTolerance;
/**
 * Checks that value A is less than value B using the provided tolerance
 * @param valueA the first value to compare
 * @param valueB the second value to compare
 * @param tolerance the tolerance to use when comparing the values
 * @returns if value A is less than value B using the provided tolerance
 */
const lessThanWithTolerance = (valueA, valueB, tolerance = exports.DEFAULT_FUZZY_EQUALITY_TOLERANCE) => {
    if ((0, exports.isNullOrUndefined)(valueA) || (0, exports.isNullOrUndefined)(valueB))
        return false;
    return valueA < valueB + tolerance;
};
exports.lessThanWithTolerance = lessThanWithTolerance;
/**
 * Checks that value A is less than value B using the provided tolerance
 * @param valueA the first value to compare
 * @param valueB the second value to compare
 * @param tolerance the tolerance to use when comparing the values
 * @returns if value A is less than value B using the provided tolerance
 */
const greaterThanWithTolerance = (valueA, valueB, tolerance = exports.DEFAULT_FUZZY_EQUALITY_TOLERANCE) => {
    if ((0, exports.isNullOrUndefined)(valueA) || (0, exports.isNullOrUndefined)(valueB))
        return false;
    return valueA + tolerance > valueB;
};
exports.greaterThanWithTolerance = greaterThanWithTolerance;
