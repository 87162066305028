import * as React from "react";

function CalendarMonthFeb24(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.49023 2.55469V4.91602M17.5098 2.55469V4.91602M2.55469 19.084V7.27734C2.55469 5.97322 3.61189 4.91602 4.91602 4.91602H19.084C20.3881 4.91602 21.4453 5.97322 21.4453 7.27734V19.084M2.55469 19.084C2.55469 20.3881 3.61189 21.4453 4.91602 21.4453H19.084C20.3881 21.4453 21.4453 20.3881 21.4453 19.084M2.55469 19.084V11.2129M21.4453 19.084V11.2129"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2062 17.3545C15.2062 17.5322 15.1407 17.6866 15.0098 17.8176C14.8882 17.9392 14.7385 18 14.5607 18H10.6596C10.3228 18 10.0094 17.9158 9.71944 17.7474C9.43879 17.579 9.21426 17.3545 9.04587 17.0738C8.87748 16.7932 8.79328 16.4845 8.79328 16.1477V14.8707C8.79328 14.4871 8.90554 14.1316 9.13007 13.8042C9.35459 13.4768 9.65395 13.2476 10.0282 13.1166L13.396 11.8537C13.4896 11.8256 13.5363 11.7554 13.5363 11.6432V10.3802C13.5363 10.2493 13.4755 10.1838 13.3539 10.1838H10.6596C10.6129 10.1838 10.5661 10.2025 10.5193 10.2399C10.4819 10.2773 10.4632 10.3241 10.4632 10.3802V10.7591C10.4632 10.9369 10.3977 11.0865 10.2667 11.2082C10.1451 11.3298 9.99542 11.3906 9.81767 11.3906H9.43879C9.26104 11.3906 9.10668 11.3298 8.97571 11.2082C8.85409 11.0865 8.79328 10.9369 8.79328 10.7591V10.3802C8.79328 10.0341 8.87748 9.72069 9.04587 9.44004C9.21426 9.15938 9.43879 8.93486 9.71944 8.76647C10.0094 8.59808 10.3228 8.51388 10.6596 8.51388H13.3539C13.6907 8.51388 13.9994 8.59808 14.2801 8.76647C14.5607 8.93486 14.7853 9.15938 14.9536 9.44004C15.122 9.72069 15.2062 10.0341 15.2062 10.3802V11.6432C15.2062 12.0361 15.094 12.3963 14.8694 12.7237C14.6449 13.0511 14.3502 13.2803 13.9854 13.4113L10.6035 14.6742C10.5099 14.7023 10.4632 14.7678 10.4632 14.8707V16.1477C10.4632 16.1945 10.4819 16.2412 10.5193 16.288C10.5661 16.3254 10.6129 16.3441 10.6596 16.3441H14.5607C14.7385 16.3441 14.8882 16.4049 15.0098 16.5266C15.1407 16.6482 15.2062 16.8025 15.2062 16.9896V17.3545Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CalendarMonthFeb24;
