"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.meanAbsoluteError = exports.median = void 0;
const median = (arr) => {
    if (arr.length === 0)
        return 0;
    const mid = Math.floor(arr.length / 2);
    const nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};
exports.median = median;
/**
 * Uses linear regression to calculate mean absolute error (not MSE given
 * how small these offsets are)
 *
 * @param points
 * @returns
 */
function meanAbsoluteError(points) {
    let xSum = 0;
    let ySum = 0;
    let xySum = 0;
    let x2Sum = 0;
    // Get the number of points
    const n = points.length;
    // Loop through the points and calculate the sums
    for (let i = 0; i < n; i++) {
        xSum += points[i][0];
        ySum += points[i][1];
        xySum += points[i][0] * points[i][1];
        x2Sum += points[i][0] * points[i][0];
    }
    // Calculate the slope (m)
    const m = (n * xySum - xSum * ySum) / (n * x2Sum - xSum * xSum);
    // Calculate the y-intercept (b)
    const b = (ySum - m * xSum) / n;
    // Initialize variable to store the mean absolute error
    let mae = 0;
    // Loop through the points and calculate the mean absolute error
    for (let i = 0; i < n; i++) {
        const yPred = m * points[i][0] + b;
        const yTrue = points[i][1];
        const error = yTrue - yPred;
        mae += Math.abs(error);
    }
    // Divide by the number of points to get the mean
    mae = mae / n;
    return { mae, m };
}
exports.meanAbsoluteError = meanAbsoluteError;
