import * as winston from "winston";
import Transport from "winston-transport";

class BrowserConsole extends Transport {
  log(info, callback) {
    setImmediate(() => {
      this.emit("logged", info);
    });

    const level = info[Symbol.for("level")];
    const message = info[Symbol.for("message")];
    const metadata = info[Symbol.for("splat")] || [];

    // eslint-disable-next-line no-console
    console[level](message, ...metadata);

    callback();
  }
}

export default winston.createLogger({
  level: "debug",
  format: winston.format.printf((args) => args.message),
  transports: [new BrowserConsole()],
});
