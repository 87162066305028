import { useRef, useMemo, useEffect, RefObject } from "react";

/**
 * useInfiniteScrollTableRef sets the `scrollTop` of the returned table
 * ref to 0 whenever an input dependency changes. This is used to reset
 * infinitely-scrolling tables when a search input changes.
 *
 * Scrolling to the top of the table ensures that only the first page
 * of results is visible, which causes the infinite fetch behavior to
 * correctly only refetch the first page, effectively restarting the
 * search.
 *
 * @param fetchDeps the data elements whose changes should trigger a table scroll
 * @returns the HTML table ref
 */
export const useInfiniteScrollTableRef = (
  fetchDeps: any[]
): RefObject<HTMLDivElement> => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const stringifiedFetchPageDeps = useMemo(
    () => JSON.stringify(fetchDeps),
    [fetchDeps]
  );
  useEffect(() => {
    if (tableContainerRef?.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [stringifiedFetchPageDeps, tableContainerRef]);

  return tableContainerRef;
};
