"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADEM_STANDARD_HEADERS = exports.ADEM_FIELDS = exports.ADEM_FIELD_MAPPER = exports.ADEM_FIELDS_METADATA = void 0;
const graphql_1 = require("../generated/graphql");
exports.ADEM_FIELDS_METADATA = {
    [graphql_1.AdemField.POLICY_NUMBER]: {
        type: graphql_1.AdemFieldType.STRING,
        width: "fixed",
        header: "Policy #",
        displayField: () => true,
    },
    [graphql_1.AdemField.CLIENT]: {
        type: graphql_1.AdemFieldType.STRING,
        width: "variable",
        header: "Client",
        displayField: () => true,
    },
    [graphql_1.AdemField.LINE]: {
        type: graphql_1.AdemFieldType.STRING,
        width: "variable",
        header: "Line",
        optional: (billingMode) => billingMode === graphql_1.BillingMode.AGENCY_BILL,
        displayField: (billingMode, _, { overrideDisplay } = {}) => overrideDisplay || billingMode === graphql_1.BillingMode.DIRECT_BILL,
    },
    [graphql_1.AdemField.DATE]: {
        type: graphql_1.AdemFieldType.DATE,
        width: "fixed",
        header: "Date",
        displayField: () => true,
    },
    [graphql_1.AdemField.CONTINGENT]: {
        type: graphql_1.AdemFieldType.STRING,
        width: "variable",
        header: "Contingent",
        optional: () => true,
        displayField: (billingMode, _, { overrideDisplay } = {}) => overrideDisplay || billingMode === graphql_1.BillingMode.DIRECT_BILL,
    },
    [graphql_1.AdemField.TRANSACTION_CODE]: {
        type: graphql_1.AdemFieldType.STRING,
        width: "variable",
        header: "Tx Code",
        optional: () => true,
        displayField: (billingMode, flags, { overrideDisplay } = {}) => {
            var _a, _b;
            return overrideDisplay ||
                ((_b = (_a = flags === null || flags === void 0 ? void 0 : flags.importTransactionCodes) === null || _a === void 0 ? void 0 : _a.valueOf()) !== null && _b !== void 0 ? _b : false) ||
                billingMode === graphql_1.BillingMode.AGENCY_BILL;
        },
    },
    [graphql_1.AdemField.PREMIUM]: {
        type: graphql_1.AdemFieldType.NUMBER,
        width: "variable",
        header: "Premium",
        optional: () => true,
        displayField: () => true,
    },
    [graphql_1.AdemField.NET_PREMIUM]: {
        type: graphql_1.AdemFieldType.NUMBER,
        width: "variable",
        header: "Net Premium",
        optional: (billingMode) => billingMode === graphql_1.BillingMode.DIRECT_BILL,
        displayField: (billingMode, _, { overrideDisplay } = {}) => overrideDisplay || billingMode === graphql_1.BillingMode.AGENCY_BILL,
    },
    [graphql_1.AdemField.RATE]: {
        type: graphql_1.AdemFieldType.NUMBER,
        width: "variable",
        header: "Rate",
        optional: () => true,
        displayField: (billingMode, _, { overrideDisplay } = {}) => overrideDisplay || billingMode === graphql_1.BillingMode.DIRECT_BILL,
    },
    [graphql_1.AdemField.MEMBERS]: {
        type: graphql_1.AdemFieldType.NUMBER,
        width: "variable",
        header: "Members",
        optional: () => true,
        displayField: (billingMode, _, { overrideDisplay } = {}) => overrideDisplay || billingMode === graphql_1.BillingMode.DIRECT_BILL,
    },
    [graphql_1.AdemField.COMMISSION]: {
        type: graphql_1.AdemFieldType.NUMBER,
        width: "variable",
        header: "Commission",
        optional: (billingMode) => billingMode === graphql_1.BillingMode.AGENCY_BILL,
        displayField: () => true,
    },
    [graphql_1.AdemField.CUSTOM]: {
        type: graphql_1.AdemFieldType.STRING,
        width: "variable",
        header: "Custom",
        optional: () => true,
        displayField: () => false,
    },
};
exports.ADEM_FIELD_MAPPER = {
    enablePdfNumberCorrection: true,
    fields: [
        {
            field: graphql_1.ImportField.POLICY_NUMBER,
            fieldType: graphql_1.ImportFieldType.STRING,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.POLICY_NUMBER].header,
            },
        },
        {
            field: graphql_1.ImportField.CLIENT,
            fieldType: graphql_1.ImportFieldType.STRING,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.CLIENT].header,
            },
        },
        {
            field: graphql_1.ImportField.LINE,
            fieldType: graphql_1.ImportFieldType.STRING,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.LINE].header,
            },
        },
        {
            field: graphql_1.ImportField.DUE_DATE,
            fieldType: graphql_1.ImportFieldType.DATE,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.DATE].header,
            },
        },
        {
            field: graphql_1.ImportField.PREMIUM,
            fieldType: graphql_1.ImportFieldType.NUMBER,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.PREMIUM].header,
            },
        },
        {
            field: graphql_1.ImportField.NET_PREMIUM,
            fieldType: graphql_1.ImportFieldType.NUMBER,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.NET_PREMIUM].header,
            },
        },
        {
            field: graphql_1.ImportField.MEMBERS,
            fieldType: graphql_1.ImportFieldType.NUMBER,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.MEMBERS].header,
            },
        },
        {
            field: graphql_1.ImportField.RATE,
            fieldType: graphql_1.ImportFieldType.NUMBER,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.RATE].header,
            },
        },
        {
            field: graphql_1.ImportField.COMMISSION,
            fieldType: graphql_1.ImportFieldType.NUMBER,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.COMMISSION].header,
            },
        },
        {
            field: graphql_1.ImportField.TRANSACTION_CODE,
            fieldType: graphql_1.ImportFieldType.STRING,
            accessor: {
                type: graphql_1.ImportFieldAccessorType.COLUMN_KEY,
                key: exports.ADEM_FIELDS_METADATA[graphql_1.AdemField.TRANSACTION_CODE].header,
            },
        },
    ],
};
exports.ADEM_FIELDS = Object.keys(exports.ADEM_FIELDS_METADATA);
exports.ADEM_STANDARD_HEADERS = Object.entries(exports.ADEM_FIELDS_METADATA)
    .filter(([field]) => field !== graphql_1.AdemField.CUSTOM)
    .map(([, { header }]) => header);
