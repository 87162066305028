import * as React from "react";

function CalendarMonthJul24(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.49023 2.55469V4.91602M17.5098 2.55469V4.91602M2.55469 19.084V7.27734C2.55469 5.97322 3.61189 4.91602 4.91602 4.91602H19.084C20.3881 4.91602 21.4453 5.97322 21.4453 7.27734V19.084M2.55469 19.084C2.55469 20.3881 3.61189 21.4453 4.91602 21.4453H19.084C20.3881 21.4453 21.4453 20.3881 21.4453 19.084M2.55469 19.084V11.2129M21.4453 19.084V11.2129"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2062 10.9836C15.2062 11.1427 15.1875 11.3064 15.1501 11.4748C15.122 11.6338 15.0799 11.7929 15.0238 11.9519L12.8066 17.5931C12.7599 17.7147 12.6803 17.8129 12.5681 17.8877C12.4652 17.9626 12.3436 18 12.2032 18H11.7963C11.553 18 11.3753 17.9064 11.263 17.7193C11.1414 17.5322 11.1227 17.3358 11.2069 17.13L13.4802 11.3345C13.4989 11.2783 13.513 11.2222 13.5223 11.1661C13.5317 11.1006 13.5363 11.0398 13.5363 10.9836V10.3662C13.5363 10.2446 13.4802 10.1838 13.3679 10.1838H10.6456C10.524 10.1838 10.4632 10.2446 10.4632 10.3662V10.7591C10.4632 10.9369 10.3977 11.0865 10.2667 11.2082C10.1451 11.3298 10.0001 11.3906 9.8317 11.3906H9.43879C9.26104 11.3906 9.10668 11.3298 8.97571 11.2082C8.85409 11.0865 8.79328 10.9369 8.79328 10.7591V9.15938C8.79328 8.97228 8.85409 8.81792 8.97571 8.6963C9.10668 8.57469 9.26104 8.51388 9.43879 8.51388H14.5607C14.7385 8.51388 14.8882 8.57469 15.0098 8.6963C15.1407 8.81792 15.2062 8.97228 15.2062 9.15938V10.9836Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CalendarMonthJul24;
