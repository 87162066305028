"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findBlocksForField = void 0;
const luxon_1 = require("luxon");
const utils_1 = require("../common/utils");
const utils_2 = require("../import/utils");
function findBlocksForField(blocks, field) {
    if (typeof field === "string") {
        return findBlocksForStringMatch(blocks, field);
    }
    if (typeof field === "number") {
        return findBlocksForNumericMatch(blocks, field);
    }
    if (field instanceof Date) {
        return findBlocksForDateMatch(blocks, luxon_1.DateTime.fromJSDate(field));
    }
    if (field instanceof luxon_1.DateTime) {
        return findBlocksForDateMatch(blocks, field);
    }
    return [];
}
exports.findBlocksForField = findBlocksForField;
function findBlocksForStringMatch(blocks, transactionData) {
    return blocks
        .filter((block) => {
        const blockText = block.Text;
        if (!transactionData || !blockText)
            return false;
        const lowerBlockText = blockText.toLowerCase().replaceAll("0", "");
        const lowerTransactionData = transactionData
            .toLowerCase()
            .replaceAll("0", "");
        return (lowerBlockText.includes(lowerTransactionData) ||
            (lowerBlockText.length >= 3 &&
                lowerTransactionData.includes(lowerBlockText)));
    })
        .map((block) => block.Id)
        .filter(utils_1.isNotNullAndNotUndefined);
}
function findBlocksForNumericMatch(blocks, txnAmount) {
    return blocks
        .filter((block) => {
        const blockText = block.Text;
        if ((0, utils_1.isNullOrUndefined)(txnAmount) || (0, utils_1.isNullOrUndefined)(blockText))
            return false;
        const blockAmount = (0, utils_2.parseNumber)(blockText, utils_2.ParseNumberMode.DEFAULT);
        if ((0, utils_1.isNullOrUndefined)(txnAmount) || (0, utils_1.isNullOrUndefined)(blockAmount))
            return false;
        return txnAmount === blockAmount;
    })
        .map((block) => block.Id)
        .filter(utils_1.isNotNullAndNotUndefined);
}
function findBlocksForDateMatch(blocks, txnDate) {
    return blocks
        .filter((block) => {
        const blockText = block.Text;
        if (!txnDate || !blockText)
            return false;
        const blockDate = getUTCDateFromString(blockText);
        if (!txnDate || !blockDate)
            return false;
        return txnDate.toISODate() === blockDate.toISODate();
    })
        .flatMap((block) => {
        var _a;
        if (block.BlockType === "WORD") {
            return [block.Id];
        }
        return (_a = block.Relationships) === null || _a === void 0 ? void 0 : _a.flatMap((relationship) => {
            if (relationship.Type === "CHILD") {
                return relationship.Ids;
            }
            return null;
        });
    })
        .filter(utils_1.isNotNullAndNotUndefined);
}
function getUTCDateFromString(dateString) {
    if (!dateString)
        return null;
    const date = (0, utils_2.parseDate)(dateString);
    return date ? luxon_1.DateTime.fromJSDate(date, { zone: "utc" }) : null;
}
