import { CheckIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon, BoltIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { classNames } from "../src/formatting";
import toast from "react-hot-toast";

export type ToastProps = {
  header: { text: string } | { content: React.ReactNode };
  body: { text: string } | { content: React.ReactNode };
  displayCloseButton?: boolean;
  onClose?: () => void;
  colorScheme?: "success" | "error" | "warning" | "info";
  headerIcon?: React.ReactNode;
};

export default function Toast({
  header,
  body,
  onClose,
  displayCloseButton = true,
  colorScheme = "success",
  headerIcon,
}: ToastProps) {
  let headerNode;
  if ("text" in header) {
    headerNode = (
      <span
        className={classNames(
          colorScheme === "warning" ? "text-yellow-800" : "text-zinc-900",
          "text-sm leading-5 font-medium"
        )}
      >
        {header.text}
      </span>
    );
  } else {
    headerNode = header.content;
  }

  let bodyNode;
  if ("text" in body) {
    bodyNode = (
      <span
        className={classNames(
          colorScheme === "warning" ? "text-yellow-700" : "text-zinc-500",
          "mt-1 text-sm leading-5"
        )}
      >
        {body.text}
      </span>
    );
  } else {
    bodyNode = body.content;
  }

  let iconNode: React.ReactNode;
  if (headerIcon) {
    iconNode = headerIcon;
  } else {
    iconNode =
      colorScheme === "success" ? (
        <CheckIcon className="w-4 mr-3 mt-0.5 text-green-600 flex-shrink-0" />
      ) : colorScheme === "warning" ? (
        <ExclamationTriangleIcon className="w-4 mr-3 mt-0.5 text-orange-600 flex-shrink-0" />
      ) : colorScheme === "error" ? (
        <XMarkIcon className="w-4 mr-3 mt-0.5 text-red-500 flex-shrink-0" />
      ) : colorScheme === "info" ? (
        <BoltIcon className="w-4 mr-3 mt-0.5 text-green-600 flex-shrink-0" />
      ) : null;
  }

  return (
    <div className="flex items-start">
      {iconNode}

      <div className="flex flex-col">
        {headerNode}
        {bodyNode}
      </div>

      {displayCloseButton && onClose && (
        <button className="ml-4" onClick={onClose}>
          <XMarkIcon className="w-4 text-zinc-400 hover:text-zinc-500" />
        </button>
      )}
    </div>
  );
}

export type TriggerToastProps = ToastProps & {
  duration?: number;
};

export function triggerToast(props: TriggerToastProps) {
  let toastId: string | undefined = undefined;
  toastId = toast(<Toast {...props} onClose={() => toast.dismiss(toastId)} />, {
    duration: props.duration ?? 3000,
  });
}
