"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluateIfGroupDerivedStateIsPerfect = void 0;
const evaluateIfGroupDerivedStateIsPerfect = (groupDerivedState, options) => {
    const { frontCarrierPayables, frontPayablesCredits } = options;
    function txnDerivedStatePerfect(txnState) {
        return (
        // Txn must always be open
        txnState.isOpen &&
            // Txn can only be in a paying statement on a single statement at a time
            !txnState.isTransactionPayingOnNonPerspectiveStatement &&
            // Txn must be either manually associated
            // or balanced with either a 0 recv bal or front carrier payables enabled
            (txnState.isManuallyAssocToEntry ||
                (txnState.isBalanced &&
                    (txnState.isZeroReceivable ||
                        frontCarrierPayables ||
                        (frontPayablesCredits && txnState.isCredit)))));
    }
    function evaluateEntryDerivedState(entryState) {
        return (((entryState.isAssociated && entryState.isEntryBalanced) ||
            // Handle manual provision association case
            (entryState.isManuallyAssoc && entryState.isDraft) ||
            // Ensure that In N out's will not block a group from being perfect
            (!entryState.isAssociated && groupDerivedState.isBalanced)) &&
            // All Txn must be perfect from perspective of the entry
            entryState.assocTxnDerivedStates.every(txnDerivedStatePerfect));
    }
    return groupDerivedState.entryDerivedStates.every(evaluateEntryDerivedState);
};
exports.evaluateIfGroupDerivedStateIsPerfect = evaluateIfGroupDerivedStateIsPerfect;
