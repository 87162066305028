import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";
import { classNames } from "../src/formatting";

export type CustomButtonProps = {
  size?: "xxs" | "xs" | "small" | "medium" | "large";
  colorScheme?:
    | "primary"
    | "secondary"
    | "inline"
    | "selected"
    | "danger"
    | "white"
    | "warn"
    | "suggestion";
  disabled?: boolean;
  active?: boolean;
  innerRef?: React.Ref<HTMLButtonElement>;
  borderless?: boolean;
};

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  CustomButtonProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      className = "",
      size = "medium",
      colorScheme = "primary",
      disabled = false,
      active = false,
      borderless = false,
      ...otherProps
    } = props;
    let colorClasses = "";
    if (colorScheme === "primary") {
      colorClasses =
        "bg-green-600 hover:bg-green-700 text-white border-transparent";
    }
    if (colorScheme === "secondary") {
      colorClasses = "bg-green-50 hover:bg-green-200 text-green-700 border-0";
    }
    if (colorScheme === "inline") {
      colorClasses = active
        ? "bg-green-50 text-green-700 border-transparent shadow-none"
        : "hover:bg-zinc-100 text-zinc-600 border-transparent shadow-none";
    }
    if (colorScheme === "danger") {
      colorClasses =
        "bg-rose-600 hover:bg-rose-700 text-white border-transparent";
    }
    if (colorScheme === "white") {
      colorClasses =
        "bg-white hover:bg-zinc-50 text-zinc-700 border-zinc-300 shadow-sm";
    }
    if (colorScheme === "selected") {
      colorClasses =
        "bg-green-50 text-green-700 border-none hover:bg-green-200";
    }
    if (colorScheme === "warn") {
      colorClasses =
        "bg-amber-600 hover:bg-amber-700 text-white border-transparent";
    }
    if (colorScheme === "suggestion") {
      colorClasses =
        "bg-blue-600 hover:bg-blue-700 text-white border-transparent";
    }

    let sizeClasses = "";
    if (size === "xxs") {
      sizeClasses = "text-xs leading-4 font-medium px-2 py-1.5";
    }
    if (size === "xs") {
      sizeClasses = "text-xs leading-4 font-medium px-3 py-2";
    }
    if (size === "small") {
      sizeClasses = "text-sm leading-4 font-medium px-3 py-2";
    }
    if (size === "medium") {
      sizeClasses = "text-sm font-medium px-4 py-2";
    }
    if (size === "large") {
      sizeClasses = "text-base font-medium px-4 py-2";
    }

    const disabledClasses = disabled
      ? "opacity-50 hover:bg-opacity-50 hover:bg-unset cursor-not-allowed"
      : "";

    const borderClasses = borderless ? "" : "border";

    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled}
        className={classNames(
          "inline-flex items-center rounded-md",
          colorClasses,
          sizeClasses,
          disabledClasses,
          borderClasses,
          className
        )}
        {...otherProps}
      >
        {children}
      </button>
    );
  }
);
