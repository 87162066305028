import { useState, useMemo } from "react";
import { Button } from "../../Button";
import InlineLoader from "../../InlineLoader";
import ModalWithContent from "../../ModalWithContent";
import { Period } from "./types";

const LockPeriodModal = ({
  defaultOpenPeriod,
  selectedPeriod,
  open,
  setOpen,
  onConfirm,
  nextPeriodDate,
}: {
  defaultOpenPeriod: Period;
  selectedPeriod: Period;
  open: boolean;
  setOpen: (val: boolean) => void;
  onConfirm: () => Promise<void>;
  nextPeriodDate: string;
}) => {
  const [loading, setLoading] = useState(false);

  const openNextPeriodOnLock = useMemo(
    () => defaultOpenPeriod.id === selectedPeriod.id,
    [defaultOpenPeriod, selectedPeriod]
  );

  const modalTitle = `Lock Period${
    openNextPeriodOnLock ? " and Open Next" : ""
  }`;

  const modalBodyFirstMessage = `Are you sure you'd like to lock this period${
    openNextPeriodOnLock ? " and open next" : ""
  }?`;

  return (
    <ModalWithContent
      title={modalTitle}
      showCloseButton
      body={
        <div className="text-base leading-6 font-normal text-zinc-700 w-[600px]">
          <div className="mb-7">{modalBodyFirstMessage}</div>
          {openNextPeriodOnLock && (
            <div className="mb-7">
              Comulate will automatically create the next period (
              {nextPeriodDate}) and set it as the default import period for all
              users.
            </div>
          )}
        </div>
      }
      footer={
        <div className="flex justify-end space-x-2">
          <Button colorScheme="white" onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              setOpen(false);
              await onConfirm();
              setLoading(false);
            }}
          >
            {loading && <InlineLoader className="mr-2" />}
            Confirm
          </Button>
        </div>
      }
      open={open}
      setOpen={setOpen}
    />
  );
};

export default LockPeriodModal;
