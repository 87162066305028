"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ELIGIBLE_HEADERS = void 0;
exports.ELIGIBLE_HEADERS = new Set([
    "date",
    "department",
    "name",
    "policy",
    "number",
    "group",
    "individual",
    "plan",
    "transaction",
    "note",
    "basis",
    "comm",
    "desc",
    "commission",
    "code",
    "issue",
    "duration",
    "type",
    "premium",
    "paid",
    "payment",
    "client",
    "line",
    "rate",
    "expiration",
    "pmpm",
    "enrolled",
    "members",
    "adjustment",
    "mode",
    "force",
    "account",
    "products",
    "paymentmode",
    "periodcovered",
    "premiumamount",
    "%paid",
    "commissionearned",
    "commissionschedule",
    "year",
    "orrenewal",
    "reinsured",
    "month",
    "coverage",
    "source",
    "tran",
    "level",
    "override",
    "structure",
    "payments",
    "acct",
    "insured",
    "description",
    "business",
    "split",
    "sign",
    "effective",
    "application",
    "process",
    "annual",
    "based",
    "processed",
    "as-earned",
    "renewal",
    "rate",
    "paid",
    "initial",
    "advance/or",
    "subsequent",
    "policies",
    "only",
    "amount",
    "tracking",
    "product",
    "tier",
    "change",
    "received",
    "current",
    "comments",
    "total",
    "payee",
    "channel",
    "carrier",
    "series",
    "takeover/rollover",
    "first",
    "year/renewal",
    "role",
    "advance",
    "calculated",
    "earned",
    "offset",
    "notes",
    "grpname",
    "grpnbr",
    "umid",
    "txnduedt",
    "monthpaid",
    "paidtodate",
    "livetype",
    "livecount",
    "prodcode",
    "commrate",
    "totalpremium",
    "splitpct",
    "paidamount",
    "earnoffamount",
    "comment",
    "miscdesc",
    "miscamount",
    "txntypecd",
    "effdate",
    "acctname",
    "acctnumber",
    "membernumber",
    "policyname",
    "policynumber",
    "contract",
    "section",
    "subgroup",
    "cert",
    "lives",
    "last",
    "begin",
    "prem",
    "company",
    "invoice",
    "service",
    "division",
    "receivable",
    "calculation",
    "billing",
    "period",
    "bill",
    "freq",
    "case",
    "policyholder",
    "system",
    "months",
    "percent",
    "eligible",
    "applied",
    "gross",
    "advance/net",
    "remit",
    "currency",
    "assignment",
    "program",
    "explanation",
    "inception",
    "payable",
    "deposit",
    "reporting",
    "company/carrier",
    "extract",
    "count",
    "level/role",
    "term",
    "base",
    "medical",
    "life",
    "guarantee",
    "activity/transaction",
    "commissionable",
    "block",
    "percentage",
    "reason",
    "compensation",
    "start",
    "general",
    "market",
    "segment",
    "sales",
    "customer",
    "billed",
    "subscriber",
    "method",
    "legacy",
    "medicare",
    "planholder",
    "benefit",
    "entity",
    "employee",
    "scale",
    "commissions",
    "payeecode",
    "location",
    "agentcode",
    "agentname",
    "billgroupname",
    "billgroupnumber",
    "coveragetype",
    "premiumdate",
    "actualpremium",
    "transtype",
    "comptype",
    "category",
    "family",
    "identified",
    "customer",
    "property",
    "shared",
    "without",
    "explicit",
    "permission",
    "health",
    "commision",
    "withholding",
    "paycheck",
    "earnings",
    "summary",
    "comp",
    "check",
    "groupoffice",
    "groupid",
    "record",
    "sequence",
    "pool",
    "risk",
    "employees",
    "detail",
    "yr/mode",
    "full",
    "timestamp",
    "report",
    "arrangement",
    "enrollment",
    "rated",
    "team",
    "roll",
    "premiums",
    "create",
    "schedule",
    "employer",
    "parent",
    "contracts",
    "member",
    "incentive",
    "bonus",
    "final",
    "prem",
    "deducted/paid",
    "disbursement",
    "healthcare",
    "blue",
    "shield",
    "bcbs",
    "delta",
    "purchaser",
    "package",
    "receipts",
    "counts",
    "seller",
    "agreement",
    "premium/fee",
    "frequency",
    "scale/commission",
    "share",
    "agencyid",
    "billingperiod",
    "rectype",
    "customertype",
    "printline",
    "schedulecode",
    "customerid",
    "customername",
    "origeffdate",
    "coveragebegin",
    "newrenewal",
    "contracttype",
    "contractcount",
    "earnedcomm",
    "premcollected",
    "prevpaid",
    "paidcomm",
    "adjustmentdesc",
    "adjustmentamt",
    "marketseg",
    "($/lives)",
    "status",
    "prod",
    "mths",
    "unearned",
    "balance",
    "cycle_date",
    "producer_id",
    "producer_name",
    "commission_division",
    "commission_type",
    "policy_year",
    "policy_eff_dt",
    "field_office",
    "policy_id",
    "policy_name",
    "employer_name",
    "coverage_eff_dt",
    "coverage_type",
    "commission_basis_type",
    "commission_basis_due_date",
    "commission_basis_posted_date",
    "commission_basis_amount",
    "commission_split_percentage",
    "commission_rate_percentage",
    "eb_commission_scale",
    "eb_billing_division",
    "eb_commission_division",
    "ind_commission_activity",
    "ind_commission_product_line",
    "ind_commission_basis_frequency",
    "ind_commission_basis_method",
    "state_of_contract",
    "rp_commission_eff_dt",
    "rp_contract_type",
    "rp_plan_type",
    "commission_earned_amount",
    "overwrite_writing_agent_id",
    "overwrite_producer_name",
    "overwrite_basis_type",
    "overwrite_basis_amount",
    "overwrite_split_percentage",
    "overwrite_percentage",
    "overwrite_earned_amount",
    "legal",
    "coverag",
    "actual",
    "trans",
    "compensatio",
    "transnbr",
    "paidvendor",
    "paidname",
    "checkdate",
    "producernumber",
    "premiummonth",
    "commissionamount",
    "groupormember",
    "mbrfirstname",
    "groupnumber",
    "enrolleeid",
    "payer",
    "major",
    "corp",
    "corporation",
    "stmt",
    "event",
    "update",
    "premduedate",
    "option",
    "collected",
    "vendor",
    "funding",
    "reference",
    "other",
    "credits",
    "charges",
    "medicareid",
    "action",
    "activity",
    "reduction",
    "prior",
    "excess",
    "ratio",
    "phase",
    "converted",
    "external",
    "employeeid",
    "retailer",
    "statementdate",
    "agentnumber",
    "groupname",
    "earning",
    "duedate",
    "commissiontype",
    "commissionrate",
    "paytowardsadvance",
    "paytostatement",
    "premiumpaiddt",
    "effectivedt",
    "policyowner",
    "policyinsured",
    "statementnumber",
]);
