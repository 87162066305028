import * as React from "react";

function CalendarMonthJan24(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.49023 2.55469V4.91602M17.5098 2.55469V4.91602M2.55469 19.084V7.27734C2.55469 5.97322 3.61189 4.91602 4.91602 4.91602H19.084C20.3881 4.91602 21.4453 5.97322 21.4453 7.27734V19.084M2.55469 19.084C2.55469 20.3881 3.61189 21.4453 4.91602 21.4453H19.084C20.3881 21.4453 21.4453 20.3881 21.4453 19.084M2.55469 19.084V11.2129M21.4453 19.084V11.2129"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5363 17.3545C13.5363 17.5322 13.4755 17.6866 13.3539 17.8176C13.2417 17.9392 13.0966 18 12.9189 18H12.526C12.3482 18 12.1939 17.9392 12.0629 17.8176C11.9413 17.6866 11.8805 17.5322 11.8805 17.3545V10.9696C11.8805 10.9322 11.8618 10.9088 11.8243 10.8994C11.7963 10.8807 11.7729 10.8807 11.7542 10.8994L11.5437 11.1099C11.4314 11.2409 11.2817 11.3064 11.0946 11.3064C11.0011 11.3064 10.9169 11.2877 10.8421 11.2503C10.7204 11.2035 10.6269 11.124 10.5614 11.0117C10.4959 10.8994 10.4632 10.7825 10.4632 10.6609V10.1136C10.4632 9.93586 10.524 9.7815 10.6456 9.65053L11.5998 8.71034C11.6653 8.65421 11.7355 8.60743 11.8103 8.57001C11.8852 8.53259 11.9694 8.51388 12.0629 8.51388H12.9189C13.0966 8.51388 13.2417 8.57469 13.3539 8.6963C13.4755 8.81792 13.5363 8.97228 13.5363 9.15938V17.3545Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CalendarMonthJan24;
